import httpClientPublic from "../httpClientPublic";

const END_POINT = "ElephantHouse";

class FirstPageService {
  async getListElephantHouse(params) {
    const config = {
      method: "get",
      url: `${END_POINT}/listElephantHouseHomePage`,
      params: params,
    };

    const response = await httpClientPublic(config);
    if (response && response.data) {
      return response.data;
    } else {
      return response || {};
    }
  }

  async getListHospitalElephant(params) {
    const config = {
      method: "get",
      url: `${END_POINT}/listHospitalElephant`,
      params: params,
    };

    const response = await httpClientPublic(config);
    return response || {};
  }

  async getGuideAboutElephant(params) {
    const config = {
      method: "get",
      url: `${END_POINT}/guideAboutElephant`,
      params: params,
    };

    const response = await httpClientPublic(config);
    return response || {};
  }
}

export default new FirstPageService();
