<template>
  <div>Navi</div>
</template>

<script>
export default {
  name: "navigation-bar",
};
</script>

<style></style>
