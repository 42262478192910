<template>
  <div
    class="ctm-datepicker"
    :class="{ 'is-invalid': invalid, 'is-disabled': disabled }"
  >
    <template v-if="!hasButton && type == 'date'">
      <v-menu
        :ref="genRef"
        v-model="menu"
        :close-on-content-click="false"
        :return-value.sync="date"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <div class="custom-datepicker-input">
            <input
              type="text"
              :value="showDateTH"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              :placeholder="placeholder"
              class="form-control-datepicker form-control gap-r"
              :class="{ 'err-invalid': invalid }"
              :disabled="disabled"
            />
            <div class="custom-datepicker-icon">
              <font-awesome-icon
                :icon="['fas', 'calendar-days']"
                class="size-h2"
              />
            </div>
          </div>
        </template>
        <v-date-picker
          v-model="date"
          no-title
          scrollable
          :locale="lang"
          :max="maxDate"
          :min="minDate"
          @input="$refs[genRef].save(date)"
          @change="(val) => onChange(val)"
          prev-icon="fa-chevron-left"
          next-icon="fa-chevron-right"
        >
          <template v-if="hasButton">
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menu = false"> ยกเลิก </v-btn>
            <v-btn text color="primary" @click="$refs[genRef].save(date)">
              เลือก
            </v-btn>
          </template>
        </v-date-picker>
      </v-menu>
    </template>
    <template v-else-if="type == 'range' || range">
      <v-menu
        :ref="genRef"
        v-model="menu"
        :close-on-content-click="false"
        :return-value.sync="date"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <div class="custom-datepicker-input">
            <input
              type="text"
              :value="showDateTH"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              :placeholder="placeholder"
              class="form-control-datepicker form-control gap-r"
              :class="{ 'err-invalid': invalid }"
              :disabled="disabled"
            />
            <div class="custom-datepicker-icon">
              <font-awesome-icon
                :icon="['fas', 'calendar-days']"
                class="size-h2"
              />
            </div>
          </div>
        </template>
        <v-date-picker
          v-model="date"
          range
          no-title
          scrollable
          :locale="lang"
          :min="minDate"
          :max="maxDate"
        >
          <!-- @change="(val) => onChangeRange(val)" -->
          <v-row>
            <v-col class="d-flex">
              <v-btn text color="danger" @click="menu = false">ยกเลิก</v-btn>
              <span class="flex"></span>
              <v-btn text color="primary" @click="onClearRange">
                ล้างค่า
              </v-btn>
              <v-btn text color="success" @click="onClickRange(date)"
                >เลือก</v-btn
              >
            </v-col>
          </v-row>
          <!-- <template v-if="hasButton">
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menu = false"> ยกเลิก </v-btn>
            <v-btn text color="primary" @click="$refs[genRef].save(date)">
              เลือก
            </v-btn>
          </template> -->
        </v-date-picker>
      </v-menu>
    </template>
    <!-- <template v-else-if="hasButton && type == 'date'">
      <v-menu
        :ref="genRef"
        v-model="menu"
        :close-on-content-click="false"
        :return-value.sync="date"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
       <template v-slot:activator="{ on, attrs }">
          <div class="custom-datepicker-input">
            <input
              type="text"
              :value="showDateTH"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              :placeholder="placeholder"
              class="form-control-datepicker form-control gap-r"
              :class="{ 'err-invalid': invalid }"
              :disabled="disabled"
            />
            <div class="custom-datepicker-icon">
              <font-awesome-icon
                :icon="['fas', 'calendar-days']"
                class="cursor-pointer size-h2"
              />
            </div>
          </div>
        </template>
        <v-date-picker
          v-model="date"
          no-title
          scrollable
          :locale="lang"
          :max="maxDate"
          :min="minDate"
          @change="(val) => onChange(val)"
          prev-icon="fa-chevron-left"
          next-icon="fa-chevron-right"
        >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
            <v-btn text color="primary" @click="$refs.menu.save(date)">
              OK
            </v-btn>
        </v-date-picker>
      </v-menu>
    </template> -->
  </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import moment from "moment";

export default {
  name: "custom-datepicker",
  props: {
    value: {
      type: [String, Array],
      default: null,
    },
    lang: {
      type: String,
      default: "th",
    },
    format: {
      type: String,
      default: "D MMM YY",
    },
    placeholder: {
      type: String,
      default: "กรุณาเลือกวันที่",
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    startDate: {
      type: String,
      default: null,
    },
    endDate: {
      type: String,
      default: null,
    },
    minDate: {
      type: String,
      default: null,
    },
    maxDate: {
      type: String,
      default: null,
    },
    invalid: {
      type: Boolean,
      default: () => false,
    },
    hasButton: {
      type: Boolean,
      default: () => false,
    },
    type: {
      type: String,
      default: () => "date",
    },
    joinWith: {
      type: String,
      default: ", ",
    },
    range: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      date: null,
      menu: false,
      modal: false,
      menu2: false,
      time: null,
      isTypeDate: true,
      genRef: "",
    };
  },
  created() {
    this.genRef = "date_menu_" + uuidv4();
  },
  mounted() {
    const app = document.createElement("div");
    app.setAttribute("data-app", true);
    document.body.append(app);
    this.isTypeDate = this.type == "date";
  },
  methods: {
    allowedDates(val) {
      let cond = moment();
      if (this.startDate !== null) {
        cond = moment(this.startDate) > moment(val);
      }
      if (this.endDate !== null) {
        cond = moment(val) > moment(this.endDate);
      }
      return cond;
    },
    onChange(val) {
      this.$emit("change", val);
      this.$emit("input", val);
    },
    onChangeRange(val) {
      this.$emit("change", val);
      this.$emit("input", val);
    },
    onClearRange() {
      const _value = [];
      this.$refs[this.genRef].save(_value);
      this.menu = false;
      this.onChangeRange(_value);
    },
    onClickRange(data) {
      //emit value out
      this.$refs[this.genRef].save(data);
      this.menu = false;
      this.onChangeRange(data);
    },
    convertFormatDate(value) {
      let arrDate = [];
      if (!this.isTypeDate && value) {
        value.sort().forEach((s) => {
          let dateText =
            this.lang === "th"
              ? this.$utils.convert.formatDateTextTH(s, this.format)
              : this.$utils.convert.formatDateTextEN(s, this.format);
          arrDate.push(dateText);
        });
        return arrDate.join(this.joinWith);
      }
    },
  },
  computed: {
    showDateTH() {
      if (this.type == "range") {
        return this.convertFormatDate(this.value);
      } else if (this.value !== null) {
        return moment(String(this.value))
          .add(543, "year")
          .locale("th")
          .format("D MMMM YYYY");
      } else {
        return null;
      }
    },
  },
};
</script>

<style></style>
