import { objToFormData } from "@/utils/convert-utils";
import httpClient from "../httpClient";
import httpClientPublic from "../httpClientPublic";

const END_POINT = "Elephant";

class ElephantService {
  async getList(params) {
    const config = {
      method: "get",
      url: `${END_POINT}/List`,
      params: params,
    };

    const response = await httpClient(config);
    return response || {};
  }

  async getListById(params) {
    const config = {
      method: "get",
      url: `${END_POINT}/ListById`,
      params: params,
    };

    const response = await httpClient(config);
    if (response && response.data) {
      return response.data;
    } else {
      return response || [];
    }
  }

  async postAdd(params) {
    let formData = new FormData();

    const {
      elephant,
      ownerElephant,
      mahout,
      dnaFile,
      elephantPassportFile,
      documentElephant,
    } = params;

    objToFormData(elephant, "elephant", formData);
    objToFormData(ownerElephant, "ownerElephant", formData);
    objToFormData(mahout, "mahout", formData);

    if (dnaFile) {
      const { file, ...rest } = dnaFile;
      objToFormData(rest, "dnaFile", formData);
      if (file) {
        formData.append(`dnaFile.fileUpload`, file);
      }
    }

    if (elephantPassportFile) {
      const { file, ...rest } = elephantPassportFile;
      objToFormData(rest, "elephantPassportFile", formData);
      if (file) {
        formData.append(`elephantPassportFile.fileUpload`, file);
      }
    }

    if (documentElephant.length > 0) {
      documentElephant.forEach((element, i) => {
        const { file, ...rest } = element;
        const paramName = `documentElephant[${i}]`;
        objToFormData(rest, paramName, formData);
        if (file) {
          formData.append(`${paramName}.fileUpload`, file);
        }
      });
    }

    const config = {
      method: "post",
      url: `${END_POINT}/add`,
      data: formData,
    };

    const response = await httpClient(config);
    return response || {};
  }

  async postEdit(params) {
    let formData = new FormData();

    const {
      elephant,
      ownerElephant,
      mahout,
      dnaFile,
      elephantPassportFile,
      documentElephant,
    } = params;

    objToFormData(elephant, "elephant", formData);
    objToFormData(ownerElephant, "ownerElephant", formData);
    objToFormData(mahout, "mahout", formData);

    if (dnaFile) {
      const { file, ...rest } = dnaFile;
      objToFormData(rest, "dnaFile", formData);
      if (file) {
        formData.append(`dnaFile.fileUpload`, file);
      }
    }

    if (elephantPassportFile) {
      const { file, ...rest } = elephantPassportFile;
      objToFormData(rest, "elephantPassportFile", formData);
      if (file) {
        formData.append(`elephantPassportFile.fileUpload`, file);
      }
    }

    if (documentElephant.length > 0) {
      documentElephant.forEach((element, i) => {
        const { file, ...rest } = element;
        const paramName = `documentElephant[${i}]`;
        objToFormData(rest, paramName, formData);
        if (file) {
          formData.append(`${paramName}.fileUpload`, file);
        }
      });
    }

    const config = {
      method: "post",
      url: `${END_POINT}/edit`,
      data: formData,
    };

    const response = await httpClient(config);
    return response || {};
  }

  async postDelete(params) {
    const config = {
      method: "post",
      url: `${END_POINT}/delete`,
      params: params,
    };

    const response = await httpClient(config);
    return response || {};
  }

  async getVerify(params) {
    const config = {
      method: "get",
      url: `${END_POINT}/verify`,
      params: params,
    };

    const response = await httpClient(config);
    return response.data || null;
  }
}

export default new ElephantService();
