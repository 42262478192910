import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";

/* import the fontawesome core */
import { library } from "@fortawesome/fontawesome-svg-core";

/* import specific icons */
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";

import ComponentPlugin from "@/components/_ComponentPlugin";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import PerfectScrollbar from "vue2-perfect-scrollbar";
import "vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css";
import Vuelidate from "vuelidate";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import vuetify from "@/plugins/vuetify";
/* import font awesome icon component */
import {
  FontAwesomeIcon,
  FontAwesomeLayers,
} from "@fortawesome/vue-fontawesome";

import mainUtils from "@/utils/main-utils";
import convertUtils from "@/utils/convert-utils";
import fileUtils from "@/utils/file-utils";

import * as VueGoogleMaps from "vue2-google-maps";

import VueGoodTablePlugin from "vue-good-table";
import "vue-good-table/dist/vue-good-table.css";

/* add font awesome icon component */
Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.component("font-awesome-layers", FontAwesomeLayers);
Vue.component("base-multiselect", { mixins: [Multiselect] });
// Vue.component("vue-multiselect", {
//   mixins: [Multiselect],
//   props: {
//     selectLabel: {
//       default: "",
//     },
//     deselectLabel: {
//       default: "X",
//     },
//     selectedLabel: {
//       default: "",
//     },
//     showLabels: {
//       default: true,
//     },
//     closeOnSelect: {
//       default: true,
//     },
//     searchable: {
//       default: true,
//     },
//     placeholder: {
//       default: "",
//     },
//   },
//   // slots:{

//   // }
// });

Vue.prototype.$utils = {
  main: { ...mainUtils },
  convert: { ...convertUtils },
  file: { ...fileUtils },
};

library.add(fas);
library.add(far);
library.add(fab);

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

Vue.use(ComponentPlugin);
Vue.use(VueSweetalert2);
Vue.use(PerfectScrollbar);
Vue.use(Vuelidate);
Vue.use(VueGoodTablePlugin);
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_G_MAP_KEY, // "AIzaSyAW2M1fksAYErBIGWMtQhMdmB_FTfJ6CFc", // "AIzaSyAeU-ROwoGrsWkhkhW44oVcQ1UnauQOnB0", // "AIzaSyDDvZsF4i9CjpLXWqDFPBlBbqeNMjGuva4", // // "AIzaSyCHehIzj8Kvdd5hIA8nrC-OkFrb-006i0Q",
    libraries: "places",
    region: "th",
    language: "th",
  },

  //// If you intend to programmatically custom event listener code
  //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  //// you might need to turn this on.
  // autobindAllEvents: false,

  //// If you want to manually install components, e.g.
  //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  //// Vue.component('GmapMarker', GmapMarker)
  //// then set installComponents to 'false'.
  //// If you want to automatically install all the components this property must be set to 'true':
  // installComponents: true,
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

Vue.mixin({
  filters: { ...convertUtils },
  methods: {
    alertSuccess({
      title,
      text,
      isConfirm,
      showCloseBtn = false,
      timer = 2500,
    } = obj) {
      let _title = [];
      if (title && title.length > 0 && typeof title === "object") {
        _title = title.map((m) => {
          return `<div class="fontalert-title">${m}</div>`;
        });
      } else if (title) {
        _title.push(`<div class="fontalert-title">${title}</div>`);
      } else {
        _title.push(`<div class="fontalert-title">ทำรายการสำเร็จ</div>`);
      }

      let _text = [];
      if (text && text.length > 0 && typeof text === "object") {
        _text = text.map((m) => {
          return `<div class="fontalert-text">${m}</div>`;
        });
      } else if (text) {
        _text.push(`<div class="fontalert-text">${text}</div>`);
      }

      return new Promise((resolve, reject) => {
        this.$swal
          .fire({
            html: `
            <div class="alert-icon"></div>
            <div class="d-col">
              ${_title.join("")}
              ${_text.join("")}
            </div>`,
            showCloseButton: showCloseBtn,
            showCancelButton: false,
            showConfirmButton: isConfirm,
            confirmButtonText: "ปิด",
            customClass: {
              // image: "custom-alert-success-image",
              container: "custom-alert-ctn-success",
              // popup: "custom-alert-success-popup",
              // icon: "custom-alert-success-icon",
              confirmButton: "btn btn-primary",
              cancelButton: "btn btn-outline-primary",
            },
            buttonsStyling: false,
            allowOutsideClick: false,
            // icon: "success",
            timer: isConfirm ? null : timer,
          })
          .then((result) => {
            if (result.isConfirmed || result.isDismissed) {
              resolve(true);
            } else {
              resolve(false);
            }
          });
      });
    },
    alertFail({
      title,
      text,
      isConfirm = true,
      showCloseBtn = false,
      timer = 2500,
    } = obj) {
      let _title = [];
      if (title && title.length > 0 && typeof title === "object") {
        _title = title.map((m) => {
          return `<div class="fontalert-title">${m}</div>`;
        });
      } else if (title) {
        _title.push(`<div class="fontalert-title">${title}</div>`);
      } else {
        _title.push(`<div class="fontalert-title">ทำรายการไม่สำเร็จ</div>`);
      }

      let _text = [];
      if (text && text.length > 0 && typeof text === "object") {
        _text = text.map((m) => {
          return `<div class="fontalert-text">${m}</div>`;
        });
      } else if (text) {
        _text.push(`<div class="fontalert-text">${text}</div>`);
      }

      return new Promise((resolve, reject) => {
        this.$swal
          .fire({
            html: `
            <div class="alert-icon"></div>
            <div class="d-col">
              ${_title.join("")}
              ${_text.join("")}
            </div>`,
            showCloseButton: showCloseBtn,
            showCancelButton: false,
            showConfirmButton: isConfirm,
            confirmButtonText: "ตกลง",
            customClass: {
              container: "custom-alert-ctn-failed",
              confirmButton: "btn btn-primary",
              cancelButton: "btn btn-outline-primary",
            },
            buttonsStyling: false,
            allowOutsideClick: false,
            // icon: "error",
            timer: isConfirm ? null : timer,
          })
          .then((result) => {
            if (result.isConfirmed || result.isDismissed) {
              resolve(true);
            } else {
              resolve(false);
            }
          });
      });
    },
    alertConfirmSave({ title, text }) {
      let _title = [];
      if (title && title.length > 0 && typeof title === "object") {
        _title = title.map((m) => {
          return `<div class="fontalert-title">${m}</div>`;
        });
      } else if (title) {
        _title.push(`<div class="fontalert-title">${title}</div>`);
      } else {
        _title.push(`<div class="fontalert-title">ยืนยันการบันทึกข้อมูล</div>`);
      }

      let _text = [];
      if (text && text.length > 0 && typeof text === "object") {
        _text = text.map((m) => {
          return `<div class="fontalert-text">${m}</div>`;
        });
      } else if (text) {
        _text.push(`<div class="fontalert-text">${text}</div>`);
      }

      return new Promise((resolve, reject) => {
        this.$swal
          .fire({
            html: `
            <div class="alert-icon"></div>
            <div class="d-col">
              ${_title.join("")}
              ${_text.join("")}
            </div>`,
            showCloseButton: true,
            showCancelButton: false,
            showConfirmButton: true,
            showDenyButton: true,
            confirmButtonText: "ยืนยัน",
            denyButtonText: "ยกเลิก",
            customClass: {
              container: "custom-alert-ctn-save",
              confirmButton: "btn btn-primary",
              denyButton: "btn btn-outline-primary",
            },
            buttonsStyling: false,
            allowOutsideClick: false,
            reverseButtons: true,
          })
          .then((result) => {
            if (result.isConfirmed) {
              resolve(true);
            } else {
              resolve(false);
            }
          });
      });
    },
    alertConfirmDelete({ title, text }) {
      let _title = [];
      if (title && title.length > 0 && typeof title === "object") {
        _title = title.map((m) => {
          return `<div class="fontalert-title">${m}</div>`;
        });
      } else if (title) {
        _title.push(`<div class="fontalert-title">${title}</div>`);
      } else {
        _title.push(`<div class="fontalert-title">ยืนยันการลบข้อมูล</div>`);
      }

      let _text = [];
      if (text && text.length > 0 && typeof text === "object") {
        _text = text.map((m) => {
          return `<div class="fontalert-text">${m}</div>`;
        });
      } else if (text) {
        _text.push(`<div class="fontalert-text">${text}</div>`);
      }

      return new Promise((resolve, reject) => {
        this.$swal
          .fire({
            html: `
            <div class="alert-icon"></div>
            <div class="d-col">
              ${_title.join("")}
              ${_text.join("")}
            </div>`,
            showCloseButton: true,
            showCancelButton: false,
            showConfirmButton: true,
            showDenyButton: true,
            confirmButtonText: "ยืนยัน",
            denyButtonText: "ยกเลิก",
            customClass: {
              container: "custom-alert-ctn-delete",
              confirmButton: "btn btn-primary",
              denyButton: "btn btn-outline-primary",
            },
            buttonsStyling: false,
            allowOutsideClick: false,
            reverseButtons: true,
          })
          .then((result) => {
            if (result.isConfirmed) {
              resolve(true);
            } else {
              resolve(false);
            }
          });
      });
    },
    alertConfirm({ title, text, showDenyButton = null }) {
      let _title = [];
      if (title && title.length > 0 && typeof title === "object") {
        _title = title.map((m) => {
          return `<div class="fontalert-text">${m}</div>`;
        });
      } else if (title) {
        _title.push(`<div class="fontalert-text">${title}</div>`);
      } else {
        _title.push(`<div class="fontalert-text">ยืนยัน</div>`);
      }

      let _text = [];
      if (text && text.length > 0 && typeof text === "object") {
        _text = text.map((m) => {
          return `<div class="fontalert-text">${m}</div>`;
        });
      } else if (text) {
        _text.push(`<div class="fontalert-text">${text}</div>`);
      }

      return new Promise((resolve, reject) => {
        this.$swal
          .fire({
            html: `
            <div class="alert-icon"></div>
            <div class="d-col">
              ${_title.join("")}
              ${_text.join("")}
            </div>`,
            showCloseButton: true,
            showCancelButton: false,
            showConfirmButton: true,
            showDenyButton: showDenyButton == null ? true : showDenyButton,
            confirmButtonText: "ยืนยัน",
            denyButtonText: "ยกเลิก",
            customClass: {
              container: "custom-alert-ctn-confirm",
              confirmButton: "btn btn-primary",
              denyButton: "btn btn-outline-primary",
            },
            buttonsStyling: false,
            allowOutsideClick: false,
            reverseButtons: true,
            icon: "question",
          })
          .then((result) => {
            if (result.isConfirmed) {
              resolve(true);
            } else {
              resolve(false);
            }
          });
      });
    },
    // alertSuccessConfirm(title, text) {
    //   let _title = [];
    //   if (title && title.length > 0 && typeof title === "object") {
    //     _title = title.map((m) => {
    //       return `<div class="fontalert-title">${m}</div>`;
    //     });
    //   } else if (title) {
    //     _title.push(`<div class="fontalert-title">${title}</div>`);
    //   } else {
    //     _title.push(`<div class="fontalert-title">ทำรายการสำเร็จ</div>`);
    //   }

    //   let _text = [];
    //   if (text && text.length > 0 && typeof text === "object") {
    //     _text = text.map((m) => {
    //       return `<div class="fontalert-text">${m}</div>`;
    //     });
    //   } else if (text) {
    //     _text.push(`<div class="fontalert-text">${text}</div>`);
    //   }

    //   return new Promise((resolve, reject) => {
    //     this.$swal
    //       .fire({
    //         html: `<div class="d-col">

    //           ${_title.join("")}
    //           ${_text.join("")}
    //         </div>`,
    //         showCancelButton: false,
    //         showConfirmButton: true,
    //         confirmButtonText: "ตกลง",
    //         customClass: {
    //           confirmButton: "btn btn-primary",
    //           cancelButton: "btn btn-outline-primary",
    //         },
    //         buttonsStyling: false,
    //         allowOutsideClick: false,
    //         icon: "success",
    //         //timer: timer,
    //       })
    //       .then(() => {
    //         resolve(true);
    //       });
    //   });
    // },
  },
});
