import store from "@/store/index";
import { StringToJson } from "./convert-utils";
import { TokenService } from "@/services/storage.service";

const permission = (to, auth) => {
  const _per = getPermissionMenu();

  //check menu with auth
  const result =
    _per.filter((f) => {
      return f.to === to || f.auth?.indexOf(auth) > -1;
    })[0] || {};

  return {
    showMenu: result.showMenu,
    isView: result.isView,
    isModified: result.isModified,
    isAdd: result.isAdd,
    isEdit: result.isEdit,
    isDelete: result.isDelete,
  };
};

const permissionByAuth = (auth) => {
  return permission(null, auth);
};

const permissionByTo = (to) => {
  return permission(to);
};

const findMenuId = (list, _auth) => {
  let find = [];

  list.forEach((element) => {
    if (element.auth && element.auth.includes(_auth) > 0) {
      find.push(element);
      return;
    } else if (element.children && element.children.length > 0) {
      const _menu = findMenuId(element.children, _auth);
      if (_menu) {
        find.push(_menu);
        return;
      }
    }
  });

  if (find.length > 0) {
    return find[0];
  } else {
    return null;
  }
};

const getLanding = () => {
  const _menu = JSON.parse(JSON.stringify(store.state.menus.menus)) || []; // clone without reference

  let _to;

  const count = _menu.length;
  for (let i = 0; i < count; i++) {
    if (_menu[i].children && _menu[i].children.length > 0) {
      const _submenu = _menu[i].children;
      const count2 = _submenu.length;

      for (let j = 0; j < count2; j++) {
        const show = findMenuLanding(_submenu[j].menuId);
        if (show) {
          _to = _submenu[j].to;
          break;
        }
      }
    }

    if (_to) {
      break;
    }

    const per = findMenuLanding(_menu[i].menuId);

    if (per) {
      _to = _menu[i].to;
      break;
    }
  }

  return _to;
};

const findMenuLanding = (id) => {
  const permission = getPermissionMenu();
  const _per =
    permission.find((f) => {
      return f.menuId === id;
    }) || false;

  if (_per.showMenu) {
    return true;
  }
  return false;
};

const getMenuAccess = () => {
  const perm = store.state.account?.menu || "";
  return StringToJson(perm) || [];
};

const getPermissionMenu = () => {
  const _menu = store.state.menus.menus;
  const _perAccessMenu = getMenuAccess();
  const isAdmin = getIsAdmin(); //super, center, dept admin
  const isSuperAdmin = getIsSuperAdmin();

  if (isSuperAdmin) {
    //force access permission
    return _menu.map((m) => {
      return {
        ...m,
        showMenu: true,
        isModified: true,
        isAdd: true,
        isEdit: true,
        isDelete: true,
        isView: true,
      };
    });
  } else if (isAdmin) {
    // center, dept admin

    //force return
    const menuAccess = _menu.map((m) => {
      const _access = _perAccessMenu.find((ff) => ff.id === m.menuId) || null;

      // //#1
      // return {
      //   ...m,
      //   showMenu: _access.isView || _access.isModified || _access.isDelete,
      //   isModified: _access.isModified,
      //   isAdd: _access.isModified,
      //   isEdit: _access.isModified,
      //   isDelete: _access.isDelete,
      //   isView: _access.isView,
      // };

      //#2
      if (m.parentId == 0) {
        //find sub menu
        const _sub1 =
          _perAccessMenu.filter(
            (ff) =>
              ff.pid === m.menuId && (ff.isView || ff.isModified || ff.isDelete)
          ) || [];
        const _subAccess = _sub1.length;

        return {
          ...m,
          showMenu:
            _access?.isModified ||
            _access?.isView ||
            _access?.isDelete ||
            _subAccess > 0,
          isModified: _access?.isModified || false,
          isAdd: _access?.isModified || false,
          isEdit: _access?.isModified || false,
          isDelete: _access?.isDelete || false,
          isView: _access?.isView || false,
        };
      } else {
        return {
          ...m,
          showMenu:
            _access?.isModified ||
            _access?.isView ||
            _access?.isDelete ||
            false,
          isModified: _access?.isModified || false,
          isAdd: _access?.isModified || false,
          isEdit: _access?.isModified || false,
          isDelete: _access?.isDelete || false,
          isView: _access?.isView || false,
        };
      }
    });

    return menuAccess;
  } else {
    //NOT ADMIN // member
    return _menu
      .filter((f) => {
        return (
          f.menuId == 1 || f.menuId == 2 || f.menuId == 3 // ||
          // f.menuId == 4 ||
          // f.parentId == 4
        );
      })
      .map((m) => {
        return {
          ...m,
          showMenu: true,
          isModified: true,
          isAdd: true,
          isEdit: true,
          isDelete: true,
          isView: true,
        };
      });
  }
};

const getUserInfo = () => {
  const _detail = store.state.account?.userInfo || {};
  return { ..._detail };
};

const getLoginUserId = () => {
  return store.state.account?.userInfo?.userId || null;
};

const getUserTypeId = () => {
  return store.state.account?.userInfo?.userTypeId || null;
};

const getIsAdmin = () => {
  return !!getLoginDeptId() || false;
};

const getIsCenter = () => {
  return getLoginDeptId() == 998 || false;
};

const getIsMember = () => {
  const _tmp = getLoginDeptId();
  return _tmp == null || _tmp == "";
};

const getIsSuperAdmin = () => {
  return getLoginDeptId() == 999 || false;
};

const getIsDeptAdmin = () => {
  return !(
    getIsCenter() ||
    getIsSuperAdmin() ||
    getIsMember() ||
    getIsDistrictOffice()
  );
};

const getLoginDeptId = () => {
  return store.state.account?.userInfo?.departmentId || null;
};

const getIsDistrictOffice = () => {
  return store.state.account?.userInfo?.isDistrictOffice;
};

const checkIsLoggedIn = () => {
  return store.state.account?.userInfo?.userId != null;
};

const getAccessToken = () => {
  return TokenService.getToken() || "";
};

export default {
  permission,
  getLanding,
  getPermissionMenu,
  getUserInfo,
  getLoginUserId,
  getIsAdmin,
  getIsCenter,
  getIsSuperAdmin,
  getIsMember,
  getIsDeptAdmin,
  getIsDistrictOffice,
  getLoginDeptId,
  checkIsLoggedIn,
  getUserTypeId,
  permissionByAuth,
  permissionByTo,
};

export {
  permission,
  getLanding,
  // getPermissionMenu,
  // getUserInfo,
  getLoginUserId,
  getIsAdmin,
  getIsCenter,
  getIsSuperAdmin,
  getIsMember,
  getIsDeptAdmin,
  getIsDistrictOffice,
  // getLoginDeptId,
  getAccessToken,
  permissionByAuth,
  permissionByTo,
};
