<template>
  <div
    id="app"
    :class="[
      cssClasses,
      isMenuCollapse ? 'menu-collapse' : 'menu-expand',
      { 'on-mobile-device': isMobileDevice, 'on-ipad': isIPad },
    ]"
    :is-large="screen.isLarge"
    :is-medium="screen.isMedium"
    :is-small="screen.isSmall"
    :browse-name="browserName"
  >
    <div v-if="isPageLoading" class="loading-page">
      <b-spinner
        v-show="isPageLoading"
        class="pre-loader"
        variant="navy"
        label="Loading..."
        style="width: 5rem; height: 5rem"
      ></b-spinner>
    </div>
    <router-view />
  </div>
</template>

<script lang="js">
import { mapGetters } from "vuex";
import { sizes, subscribe, unsubscribe } from "@/utils/media-query";

function getScreenSizeInfo() {
  const screenSizes = sizes();
  return {
    isMedium: screenSizes["screen-medium"],
    isLarge: screenSizes["screen-large"],
    isSmall: screenSizes["screen-small"],
    cssClasses: Object.keys(screenSizes).filter((cl) => screenSizes[cl]).map(m=> `app-${m}`).join(" "),
  };
}

export default {
  name: "app",
  data() {
    return {
      screen: getScreenSizeInfo(),
    };
  },
  created() {
    window._appp = this;
    const _env = process.env.NODE_ENV;
    const disabledLog = !process.env.VUE_APP_CONSOLE_LOG;
    console.log("_env", _env, disabledLog);
    console.log("version", process.env.VUE_APP_BASE_WEB_VERSION);

    if ((_env === "production" && disabledLog) || disabledLog) {
      // Disable console log // on production
      console.log = function () {};
    }

    this.screenSizeChanged()
    this.$store.commit("setMenuCollapse", this.screen.isSmall || false);
  },
  computed: {
    ...mapGetters(["isPageLoading","isMenuCollapse"]),
    cssClasses() {
      return this.screen.cssClasses;
    },
    isMobileDevice(){
      return this.detectMobileDevice();
    },
    browserName(){
      return this.detectBrowser()
    },
    isAndroid(){
      return navigator.userAgent.match(/Android/i) || false;
    },
    isIPad(){
      return navigator.userAgent.match(/iPad/i) || false;
    },
  },
  methods: {
    screenSizeChanged() {
      this.screen = getScreenSizeInfo();
    },
    detectMobileDevice(){
      // check for common mobile user agents
      if (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/webOS/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i) || navigator.userAgent.match(/BlackBerry/i) || navigator.userAgent.match(/Windows Phone/i)) {
        // the user is using a mobile device, so redirect to the mobile version of the website
        // window.location = "https://www.yoursite.com/mobile";
        return true;
      }

      return false;
    },
    detectBrowser() {
      const userAgent = navigator.userAgent;
      let browserName;

      if (userAgent.match(/chrome|chromium|crios/i)) {
        browserName = "Chrome";
      } else if (userAgent.match(/firefox|fxios/i)) {
        browserName = "Firefox";
      } else if (userAgent.match(/safari/i)) {
        browserName = "Safari";
      } else if (userAgent.match(/opr\//i)) {
        browserName = "Opera";
      } else if (userAgent.match(/edg/i)) {
        browserName = "Edge";
      } else if (userAgent.match(/android/i)) {
        browserName = "Android";
      } else if (userAgent.match(/iphone/i)) {
        browserName = "iPhone";
      } else {
        browserName = "Unknown";
      }

      return browserName
    },
  },
  mounted() {
    subscribe(this.screenSizeChanged);
  },
  beforeDestroy() {
    unsubscribe(this.screenSizeChanged);
  },
  watch:{
    screen:{
      handler: function (value) {
        this.$store.commit("setMenuCollapse", value?.isSmall || false);
      },deep:true
    }
  }
}
</script>

<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.loading-page {
  height: 100%;
  width: 100%;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(white, 0.5);
  z-index: 10000;
}
</style>
