<template>
  <div>
    <aside id="SidebarLayout" class="sidebar-layout">
      <div class="sidebar-perfect-scrollbar">
        <perfect-scrollbar>
          <nav class="px-3">
            <ul class="ul-menu">
              <template v-for="(menu, index) in menus">
                <!--  parent-level , cannot click -->
                <template v-if="menu.parentId == 0 && menu.hasSub">
                  <li
                    class="li-menu parent-level"
                    :key="index"
                    :class="{
                      'active-submenu': menu.active || menu.expand,
                      active: menu.active || menu.expand,
                    }"
                  >
                    <div class="menu-name-content">
                      <font-awesome-icon
                        class="nav-icon size-h3"
                        v-if="menu.iconClass"
                        :icon="menu.iconClass"
                        :size="menu.iconSize"
                      />
                      <span class="flex-fill menu-title">{{ menu.title }}</span>
                      <!-- <font-awesome-icon
                      class="nav-icon-expand"
                      :icon="['fas', 'angle-up']"
                    /> -->
                    </div>
                    <ul v-if="menu.children" class="ul-submenu">
                      <template v-for="(child, index2) in menu.children">
                        <li
                          class="li-submenu sub-level"
                          :key="index2"
                          :class="{
                            'active-submenu': child.active || child.expand,
                            active: child.active || child.expand,
                          }"
                        >
                          <router-link class="w-100" :to="{ name: child.to }">
                            <div class="menu-name-content">
                              <span class="pl-4 menu-title">{{
                                child.title
                              }}</span>
                            </div>
                          </router-link>
                        </li>
                      </template>
                    </ul>
                  </li>
                </template>
                <!--  parent-level , can click -->
                <template v-else-if="menu.parentId == 0">
                  <li
                    class="li-menu parent-level-click"
                    :key="index"
                    :class="{
                      'active-submenu': menu.active || menu.expand,
                      active: menu.active || menu.expand,
                    }"
                  >
                    <router-link
                      class="w-100"
                      :to="{ name: menu.to }"
                      :class="{ clickkkk: (menu.click = true) }"
                    >
                      <div class="menu-name-content">
                        <font-awesome-icon
                          class="nav-icon size-h3"
                          v-if="menu.iconClass"
                          :icon="menu.iconClass"
                          :size="menu.iconSize"
                        />
                        <span class="menu-title">{{ menu.title }}</span>
                      </div>
                    </router-link>
                  </li>
                </template>
                <!-- have children -->
                <!-- <template v-else>
                <li
                  class="li-menu sub-level"
                  :key="index"
                  :class="{
                    'active-submenu': menu.active || menu.expand,
                    active: menu.active || menu.expand,
                  }"
                >
                  <router-link class="w-100" :to="{ name: menu.to }">
                    <div class="menu-name-content">
                      <font-awesome-icon
                        class="nav-icon"
                        v-if="menu.iconClass"
                        :icon="menu.iconClass"
                      />
                      <span class="pl-4 menu-title"
                        ><font-awesome-icon
                          class="mr-2"
                          icon="fa-solid fa-circle"
                          style="font-size: 8px; margin-bottom: 2px"
                        />{{ menu.title }}</span
                      >
                    </div>
                  </router-link>
                </li>
              </template> -->
              </template>
            </ul>
          </nav>
        </perfect-scrollbar>
      </div>
      <div class="sidemenu-toggle-collapse" @click="sidemenuCollapse()">
        <img src="@/assets/images/menu-button-left.png" />
      </div>
    </aside>
  </div>
</template>

<script>
export default {
  name: "sidebar-layout",
  data() {
    return {
      menus: [],
    };
  },
  async created() {
    window._sidebar = this;

    let _menu = this.$utils.main.getPermissionMenu();

    // this.menus = _menu
    //   .map((m) => {
    //     return {
    //       ...m,
    //       active: false,
    //       expand: false,
    //     };
    //   })
    //   .filter((f) => f.showMenu)
    //   .sort(function (a, b) {
    //     return parseFloat(a._order) - parseFloat(b._order);
    //   });

    let lstMenu = [];

    _menu
      .filter((f) => f.showMenu)
      .forEach((ele) => {
        let lstMenuChild = [];

        if (ele.parentId == 0 && ele.hasSub) {
          lstMenuChild =
            _menu.filter((f) => {
              return f.parentId == ele.menuId && f.showMenu;
            }) || [];

          let tempmenu = null;
          if (ele.hasSub && lstMenuChild.length > 0) {
            tempmenu = JSON.parse(JSON.stringify(ele));
            tempmenu.children = [...lstMenuChild];
          }
          if (tempmenu) {
            lstMenu.push(tempmenu);
          }
        } else if (ele.parentId == 0) {
          lstMenu.push(ele);
        }
        // if (element.parentId !== 0) {
        //   element.children.forEach((element2) => {
        //     const show = this.findSidebar(element2.menuId);
        //     if (show) {
        //       lstMenuChild.push(element2);
        //     }
        //   });
        // }
      });

    this.menus = lstMenu;

    this.$nextTick(() => {
      this.matchMenuSide(this.$route);
    });
  },
  methods: {
    sidemenuCollapse() {
      this.$store.commit("setMenuCollapse");
      // this.$emit("toggle-menu-func", value);
    },
    matchMenuSide(route) {
      const _name = route.name;
      this.menus.map((f) => {
        f.active = false;
        f.expand = false;

        if (f.to === _name) {
          f.active = true;
          f.expand = true;
        } else if (f.to !== undefined && route.meta?.rootMenu === f.to) {
          f.active = true;
          f.expand = true;
        }
        return { ...f };
      });
    },
  },
  watch: {
    $route(route) {
      this.matchMenuSide(route);
    },
  },
};
</script>

<style></style>
