import httpClient from "../httpClient";

const END_POINT = "Common";

class CommonService {
  async getFile(fileId) {
    const config = {
      method: "get",
      url: `${END_POINT}/getFile/${fileId}`,
      responseType: "blob",
    };

    const response = await httpClient(config);
    return response || undefined;
  }
}

export default new CommonService();
