<template>
  <div id="AppLayout">
    <app-header></app-header>

    <app-sidebar></app-sidebar>

    <main class="content-wrapper-app-layout">
      <router-view></router-view>
    </main>

    <!-- <div class="sidemenu-backdrop"></div> -->

    <!-- <app-footer></app-footer> -->
  </div>
</template>

<script>
import AppHeader from "./Header.vue";
import AppSidebar from "./Sidebar.vue";
import AppFooter from "./Footer.vue";

export default {
  name: "app-layout",
  components: { AppHeader, AppSidebar, AppFooter },
  data() {
    return {
      // isMenuCollapse: false,
    };
  },
  // created() {
  //   window.addEventListener("click", this.clickSideMenuBackdrop);
  // },
  // destroyed() {
  //   window.removeEventListener("click", this.clickSideMenuBackdrop);
  // },
  // methods: {
  //   clickSideMenuBackdrop(e) {
  //     const _target = e.target.classList || [];
  //     if (_target[0] === "sidemenu-backdrop") {
  //       this.isMenuCollapse = true;
  //     }
  //   },
  // },
};
</script>

<!-- <style lang="scss">
#AppLayout {
  background-attachment: fixed;
  background-image: url("@/assets/images/bg-app.png");
}
</style> -->
