<template>
  <div class="phone-multiple" :id="genCompId">
    <div class="row">
      <template v-for="(data, index) in tmpArr">
        <div
          class="col-12 col-sm-12 col-md-12 col-lg-6 d-inline-flex"
          :key="index"
        >
          <input
            type="text"
            :placeholder="placeholder"
            class="form-control"
            v-model="data.value"
            :disabled="!canInput"
          />
          <div class="ml-3" v-if="tmpArr.length > 1 && canInput">
            <button
              type="button"
              class="btn button-delete"
              @click="removeInput(data, index)"
            >
              <font-awesome-icon
                :icon="['fas', 'trash-can']"
                class="font-white size-h2"
              />
            </button>
          </div>
          <!-- <font-awesome-icon
            :icon="['fas', 'x']"
            class="ml-3"
            @click="removeInput(data, index)"
          /> -->
        </div>
      </template>
    </div>
    <div
      class="row"
      v-if="tmpArr <= maxNumber || (maxNumber == null && canInput)"
    >
      <div class="col">
        <div class="my-3">
          <button type="button" class="btn btn-primary" @click="addNewInput">
            เพิ่ม
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
export default {
  name: "phone-multiple",
  props: {
    mode: {
      type: String,
      default: "add", // add, edit, view
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    maxNumber: {
      type: Number,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
      default: "หมายเลขโทรศัพท์",
    },
    regexp: {
      type: RegExp,
      default: null,
    },
    // modelValue: {
    //   type: [Array, String],
    //   default: null,
    // },
    initValue: {
      type: [Array, String],
      default: null,
    },
  },
  data() {
    return {
      genCompId: null,
      tmpArr: [],
    };
  },
  methods: {
    change(event) {
      const _tmp = event;
      this.$emit("input", _tmp);
      // this.$emit("change", _tmp);
    },
    addNewInput() {
      this.tmpArr.push({
        value: null,
      });
    },
    removeInput(data, index) {
      this.tmpArr.splice(index, 1);
    },
    convertToArray(data) {
      const tmpp = data?.split(",") || [""];
      return tmpp.map((m) => {
        return {
          value: m,
        };
      });
    },
  },
  created() {
    window._phone = this;

    this.genCompId = "phone-multi_" + uuidv4();
  },
  mounted() {
    // this.tmpArr = this.convertToArray(this.modelValue);
    this.tmpArr = this.convertToArray(this.initValue);
  },
  watch: {
    // modelValue: {
    //   handler: function (val) {
    //     this.tmpArr = this.convertToArray(val);
    //   },
    //   deep: true,
    // },
    initValue: {
      handler: function (val) {
        this.tmpArr = this.convertToArray(val);
      },
      deep: true,
    },
    tmpArr: {
      handler: function (val) {
        this.change(
          val
            .filter((f) => f.value)
            .map((m) => m.value)
            .join()
        );
      },
      deep: true,
    },
  },
  computed: {
    canInput() {
      if (this.disabled) {
        return false;
      }
      return this.mode != "view";
    },
  },
};
</script>

<style></style>
