import moment from "moment/moment";

const objRemoveNull = (object) => {
  Object.keys(object).forEach((key) => {
    if (object[key] === null) {
      delete object[key];
    }
  });

  return object;
};

const objToFormData = (object, mainstr = null, form_data) => {
  object = objRemoveNull(object);

  for (var key in object) {
    form_data.append(`${mainstr ? mainstr + "." : ""}${key}`, object[key]);
  }

  return form_data;
};

const objToFormDataFile = (
  form_data,
  objectRest,
  files = [],
  mainstr = null
) => {
  objectRest = objRemoveNull(objectRest);

  for (var key in objectRest) {
    form_data.append(`${mainstr ? mainstr + "." : ""}${key}`, objectRest[key]);
  }

  if (files.length > 0) {
    files.forEach((file) => {
      form_data.append(`${mainstr ? mainstr + "." : ""}file`, file);
    });
  }

  return form_data;
};

const editFileToFormDataFile = (formData, objFile, paramName) => {
  const { file, ...rest } = objFile;
  objToFormData(rest, paramName, formData);
  if (file) {
    formData.append(`${paramName}.file`, file);
  }

  return formData;
};

const editFileToFormDataFileList = (formData, arrFile, paramName) => {
  //update
  arrFile.forEach((element, i) => {
    const _paramName = `${paramName}[${i}]`;
    if (element.isDelete) {
      //old, update
      objToFormData(element, _paramName, formData);
    } else {
      //new, add
      const { file, ...restFile } = { ...element };
      objToFormDataFile(formData, restFile, [file], _paramName);
    }
  });

  return formData;
};

const logFormData = (formData) => {
  console.log("startttt");
  for (const pair of formData.entries()) {
    console.log(pair[0], ",", pair[1]);
  }
  console.log("doneeee");
};

const toFixed = (number, limit) => {
  if (number !== null && number !== "" && number !== undefined) {
    return Number(number).toFixed(limit);
  }
  return number;
};

const toCommas = (number, limit) => {
  if (
    number !== null &&
    number !== "" &&
    number !== undefined &&
    typeof number === "number"
  ) {
    // .toLocaleString()
    return Number(number)
      .toFixed(limit)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return number;
};

const JsonToString = (json = null) => {
  try {
    if (json) {
      return JSON.stringify(json);
    } else {
      return json;
    }
  } catch (e) {
    return undefined;
  }
};

const StringToJson = (data = "") => {
  try {
    if (data) {
      return JSON.parse(data);
    } else {
      return data;
    }
  } catch (e) {
    return undefined;
  }
};

const objToQueryStr = (obj) => {
  if (!obj) {
    return obj;
  }

  return Object.keys(obj)
    .filter((key) => obj[key])
    .map((key) => key + "=" + obj[key])
    .join("&");
};

const checkFileSize = (file, size) => {
  let check = null;

  if (size === null) {
    size = 2097152;
  }

  //default size => 2097152 = 2MB
  if (file.size > size) {
    check = false;
  } else {
    check = true;
  }

  return check;
};

const formatBytes = (a, b = 2) => {
  if (0 === a) return "0 Bytes";
  const c = 0 > b ? 0 : b,
    d = Math.floor(Math.log(a) / Math.log(1024));
  return (
    parseFloat((a / Math.pow(1024, d)).toFixed(c)) +
    " " +
    ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][d]
  );
};

const isTypeFileImage = (file = []) => {
  const _typeFile = ["image/jpeg", "image/jpg", "image/png"];

  if (file != null) {
    return _typeFile.includes(file.type);
  } else {
    return false;
  }

  // file.forEach((e) => {
  //   let checkType = !_typeFile.includes(e.type); //T ไม่รองรับ
  //   if (checkType) {
  //     return false;
  //   }
  // });

  // return true;
};

const getExtension = (filename) => {
  var parts = filename.split(".");
  return parts[parts.length - 1];
};

const isTypeFilePdf = (file = [], typeFile = []) => {
  const _typeFile =
    typeFile !== null && typeFile.length > 0
      ? typeFile
      : [
          "application/pdf",
          // "image/jpeg",
          // "image/jpg",
          // "image/png",
          // 'application/xls',
          // 'application/xlsx',
          // 'application/docx',
          // "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          // "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        ];

  file.forEach((e) => {
    let checkType = !_typeFile.includes(e.type); //T ไม่รองรับ
    if (checkType) {
      // response = false
      return false;
    }
  });

  return true;
};

const convertURIToImageData = (url) => {
  return new Promise((resolve, reject) => {
    if (!url) {
      return reject();
    }

    const image = new Image();
    image.crossOrigin = "Anonymous";
    image.src = url;
  });
};

const blobUrlToFile = (blobUrl) =>
  new Promise((resolve) => {
    fetch(blobUrl).then((res) => {
      res.blob().then((blob) => {
        // please change the file.extension with something more meaningful
        // or create a utility function to parse from URL
        const file = new File([blob], "png", { type: blob.type });
        resolve(file);
      });
    });
  });

const isCitizenIdFormat = (citizenId) => {
  let sum = 0;
  if (!isNumeric(citizenId)) return false;
  if (citizenId.substring(0, 1) == 0) return false;
  if (citizenId.length != 13) return false;
  for (let i = 0; i < 12; i++)
    sum += parseFloat(citizenId.charAt(i)) * (13 - i);
  if ((11 - (sum % 11)) % 10 != parseFloat(citizenId.charAt(12))) return false;
  return true;
};

const isNumeric = (value) => {
  return /^-?(0|INF|(0[1-7][0-7]*)|(0x[0-9a-fA-F]+)|((0|[1-9][0-9]*|(?=[\.,]))([\.,][0-9]+)?([eE]-?\d+)?))$/.test(
    value
  );
};

const formatDateTextTH = (value, format) => {
  if (value) {
    return moment(String(value))
      .add(543, "year")
      .locale("th")
      .format(format ? format : "D MMM YY");
  }
  return value;
};

const generateDateRangeTextTH = (value, format) => {
  if (value && value.length != 0) {
    const _date = value
      .map((m) => {
        return formatDateTextTH(m, format);
      })
      .filter(unique);

    if (_date.length === 1) {
      return _date[0];
    } else {
      return `${_date[0]} - ${_date[1]}`;
    }
  }
  return value;
};

const unique = (value, index, self) => {
  return self.indexOf(value) === index;
};

//use in javascript
export default {
  toFixed,
  toCommas,
  JsonToString,
  StringToJson,
  objToFormData,
  objToFormDataFile,
  logFormData,
  objRemoveNull,
  checkFileSize,
  formatBytes,
  isTypeFilePdf,
  isTypeFileImage,
  convertURIToImageData,
  blobUrlToFile,
  isCitizenIdFormat,
  editFileToFormDataFile,
  editFileToFormDataFileList,
  formatDateTextTH,
  generateDateRangeTextTH,
};

//use in pipe m import {}
export {
  toFixed,
  toCommas,
  JsonToString,
  StringToJson,
  objToFormData,
  objToFormDataFile,
  logFormData,
  objRemoveNull,
  checkFileSize,
  formatBytes,
  isTypeFilePdf,
  isTypeFileImage,
  convertURIToImageData,
  blobUrlToFile,
  editFileToFormDataFile,
  editFileToFormDataFileList,
  formatDateTextTH,
  generateDateRangeTextTH,
  objToQueryStr,
};
