<template>
  <div id="RegisterLayout">
    <div class="header-register px-3 py-2 shadow-sm">
      <div class="d-flex align-items-center">
        <img
          class="header-logo-2"
          src="@/assets/images/logo52x52.png"
          style="width: 52px; height: 52px"
        />
        <div class="size-h2 px-3">
          ระบบแจ้งประสบภัยพิบัติอัจฉริยะสำหรับปางช้างแบบเป็นปัจจุบัน กรมปศุสัตว์
        </div>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
