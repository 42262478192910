<template>
  <div class="core-file-upload">
    <div class="d-flex align-items-center">
      <label for="" class="form-label">{{ titleFileUpload }}</label>
      <span class="size-footnote font-danger mt-2 ml-2">{{
        isMultiple
          ? "(สามารถใส่ไฟล์ได้มากกว่า 1 ไฟล์)"
          : "(สามารถใส่ไฟล์ได้ 1 ไฟล์)"
      }}</span>
    </div>
    <div v-show="showUploadBtn && !disabled" class="file-doc-input mb-3">
      <input
        type="file"
        :name="genCompId"
        :id="genCompId"
        class="doc-input"
        :multiple="isMultiple"
        accept="application/pdf,image/jpeg"
        @change="uploadFile($event)"
      />
      <label class="doc-label" :for="genCompId">
        <font-awesome-icon :icon="['fas', 'upload']" class="mr-2" />
        <div class="">เพิ่มเอกสาร</div></label
      >
    </div>

    <div v-show="disabled && file_show.length == 0" class="file-not-uploaded">
      ไม่มีเอกสารอัพโหลด
    </div>

    <div
      v-for="(data, index) in file_show"
      :key="`show_${genCompId}_${index}`"
      class="file-doc-uploaded"
    >
      <div>
        <font-awesome-icon
          class="font-primary mr-2"
          :icon="['fas', 'paperclip']"
        />
        {{ data.name }}
      </div>
      <div class="ml-2">
        <font-awesome-icon
          class="font-primary size-h1"
          :icon="['fas', 'download']"
          @click="downloadFile(data, index)"
        />
        <font-awesome-icon
          class="font-danger ml-3 size-h1"
          :icon="['fas', 'trash-can']"
          @click="deleteFile(data, index)"
          v-show="!disabled"
        />
      </div>
    </div>
  </div>
</template>

<script>
// import { downloadFile } from "@/utils/file-utils.js";
import { v4 as uuidv4 } from "uuid";
// import { ElephantHouseService } from "@/services/main.service";

export default {
  name: "custom-file-upload",
  props: {
    mode: {
      type: String,
      default: "add", // add, edit, view
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    titleFileUpload: {
      type: String,
      default: "-",
    },
    isMultiple: {
      type: Boolean,
      default: false,
    },
    filesUploaded: {
      type: [Array, Object],
      default: () => [],
    },
    fileSize: {
      type: Number,
      default: () => {
        1048576; //bytes
      },
    },
    sumFileSize: {
      type: Number,
      default: () => 0,
    },
  },
  data() {
    return {
      genCompId: null,
      file_new: [],
      file_old: [],
      preview_old: [],
      file_show: [],
      showUploadBtn: false,
    };
  },
  created() {
    window._fileupload = this;

    this.genCompId = "doc-input_" + uuidv4();
  },
  mounted() {
    this.showUploadBtn = this.getCheckShowUpload();
  },
  methods: {
    async uploadFile(event) {
      if (this.disabled) {
        return;
      }

      if (this.isMultiple) {
        const input = Array.from(event.target.files);
        input.forEach((f) => {
          const _ttFile = { tmpFile: f, name: f.name, isAdd: true };
          this.file_new.push(_ttFile);

          this.file_show = [...this.file_new];
        });
      } else {
        const input = event.target.files[0];
        const _ttFile = { tmpFile: input, name: input.name, isAdd: true };
        this.file_new = [_ttFile];

        this.file_show = [...this.file_new];
      }

      this.change();
    },
    deleteFile(data, index) {
      if (this.disabled) {
        return;
      }

      if (data.isAdd) {
        this.file_new.splice(index, 1);
      } else {
        //old
        data["isDelete"] = true;
      }

      //emit
      this.change();
    },
    change() {
      this.showUploadBtn = this.getCheckShowUpload();

      this.file_show = [
        ...this.file_new,
        ...this.file_old.filter((f) => !f.isDelete),
      ];

      const forReturn = [...this.file_new, ...this.file_old];

      this.$emit("input", forReturn);
    },
    getCheckShowUpload() {
      return (
        this.isMultiple ||
        (!this.isMultiple &&
          this.file_new.length +
            this.file_old.filter((f) => !f.isDelete).length ==
            0)
      );
    },
    async downloadFile(data, index) {
      if (data.isAdd) {
        //new
        const fileURL = URL.createObjectURL(data.tmpFile);
        window.open().location.href = fileURL;
      } else {
        //old
        const result = await this.$utils.file.downloadFileByFileId(
          data.tmpFile.fileId
        );
        if (!result) {
          this.alertFail("เกิดข้อผิดพลาด");
        }
        // await ElephantHouseService.postFileStream(data.tmpFile.fileId)
        //   .then((response) => {
        //     if (response) {
        //       const _blob = new Blob([response]);
        //       downloadFile({
        //         blob: _blob,
        //         filename: `${data.name}`,
        //         mimeType: response.type,
        //       });
        //     } else {
        //       this.alertFail({ title: "ไม่สามารถดาวน์โหลดไฟล์ได้" });
        //     }
        //   })
        //   .catch((err) => {
        //     this.alertFail({ title: err?.data?.myMessage });
        //   });
      }
    },
  },
  watch: {
    filesUploaded: {
      handler: function (val) {
        if (!this.isMultiple && val !== null) {
          this.file_old = [val].map((m) => {
            return {
              tmpFile: m,
              name: m.fileName,
              isOld: true,
            };
          });
        } else if (val?.length > 0) {
          this.file_old = [...val].map((m) => {
            return {
              tmpFile: m,
              name: m.fileName,
              isOld: true,
            };
          });
        }

        //emit
        this.change();
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.core-file-upload {
  .file-doc-uploaded {
    background: $field-hover;
    padding: 15px 16px;
    border-radius: 9px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  .file-not-uploaded {
    background: $field-hover;
    padding: 15px 16px;
    border-radius: 9px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
}
</style>
