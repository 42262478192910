<template>
  <base-multiselect
    class="custom-multiselect"
    v-model="value2"
    :options="options"
    :multiple="multiple"
    :label="label"
    :track-by="trackBy"
    :selectLabel="selectLabel"
    :selectedLabel="selectedLabel"
    :deselectLabel="!allowEmpty ? '' : deselectLabel"
    :allow-empty="allowEmpty"
    :placeholder="placeholder"
    :disabled="disabled"
    @select="change"
    @remove="deselected"
  >
    <span slot="noResult">ไม่มีข้อมูล</span>
    <span slot="noOptions">ไม่มีข้อมูล</span>
  </base-multiselect>
</template>

<script>
export default {
  name: "vue-multiselect",
  props: {
    selectedLabel: {
      type: String,
      default: "x",
    },
    selectLabel: {
      type: String,
      default: "",
    },
    deselectLabel: {
      type: String,
      default: "x",
    },
    allowEmpty: {
      type: Boolean,
      default: true,
    },
    value: {
      type: [Object, Array, String],
      default: null,
    },
    options: {
      type: Array,
      default: null,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "text",
    },
    trackBy: {
      type: String,
      default: "id",
    },
    placeholder: {
      type: String,
      default: "กรุณาเลือก",
    },
  },
  // emits: ["update:modelValue"],
  data() {
    return {
      value2: null,
    };
  },
  methods: {
    change(event) {
      const _tmp = event;
      this.$emit("input", _tmp);
      this.$emit("change", _tmp);
    },
    deselected(event) {
      const _tmp = null;
      this.$emit("input", _tmp);
      this.$emit("change", _tmp);
    },
  },
  mounted() {
    this.value2 = this.value;
  },
  watch: {
    value: {
      handler: function (val) {
        this.value2 = val;
      },
      deep: true,
    },
  },
};
</script>
