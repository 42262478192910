import axios from "axios";

// const headers = {
// }

const httpClient = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  // headers: headers
});

const interceptorRequestP = (request) => {
  // set no header token
  return request;
};

const interceptorRequestErrorP = (error) => {
  return Promise.reject(error);
};

const interceptorResponseP = async (response) => {
  return response.data;
};

const manageError = (error) => {
  if (
    error.response.status == null ||
    error.response.status == 0 ||
    error.response.status == undefined
  ) {
    return { data: { myMessage: error.message }, status: 0 };
  }

  if (error.response.status != 400) {
    return { data: { myMessage: error.message }, status: 0 };
  }

  const { data, status } = error.response || {};

  return {
    data: { ...data, myMessage: data.message || data.title },
    status: status,
  };
};

const interceptorResponseErrorP = async (error) => {
  console.log(error);

  if (error.response === undefined) {
    console.log("error.response", error.response);
    return Promise.reject({});
  }

  // Reject promise if usual error
  if (error.response && error.response.status !== 401) {
    console.log("error.response.status", error.response.status);

    return Promise.reject(manageError(error));
  }

  // status 401

  return Promise.reject({});
};

httpClient.interceptors.request.use(
  interceptorRequestP,
  interceptorRequestErrorP
);

httpClient.interceptors.response.use(
  interceptorResponseP,
  interceptorResponseErrorP
);

export default httpClient;
