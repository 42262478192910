var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('aside',{staticClass:"sidebar-layout",attrs:{"id":"SidebarLayout"}},[_c('div',{staticClass:"sidebar-perfect-scrollbar"},[_c('perfect-scrollbar',[_c('nav',{staticClass:"px-3"},[_c('ul',{staticClass:"ul-menu"},[_vm._l((_vm.menus),function(menu,index){return [(menu.parentId == 0 && menu.hasSub)?[_c('li',{key:index,staticClass:"li-menu parent-level",class:{
                    'active-submenu': menu.active || menu.expand,
                    active: menu.active || menu.expand,
                  }},[_c('div',{staticClass:"menu-name-content"},[(menu.iconClass)?_c('font-awesome-icon',{staticClass:"nav-icon size-h3",attrs:{"icon":menu.iconClass,"size":menu.iconSize}}):_vm._e(),_c('span',{staticClass:"flex-fill menu-title"},[_vm._v(_vm._s(menu.title))])],1),(menu.children)?_c('ul',{staticClass:"ul-submenu"},[_vm._l((menu.children),function(child,index2){return [_c('li',{key:index2,staticClass:"li-submenu sub-level",class:{
                          'active-submenu': child.active || child.expand,
                          active: child.active || child.expand,
                        }},[_c('router-link',{staticClass:"w-100",attrs:{"to":{ name: child.to }}},[_c('div',{staticClass:"menu-name-content"},[_c('span',{staticClass:"pl-4 menu-title"},[_vm._v(_vm._s(child.title))])])])],1)]})],2):_vm._e()])]:(menu.parentId == 0)?[_c('li',{key:index,staticClass:"li-menu parent-level-click",class:{
                    'active-submenu': menu.active || menu.expand,
                    active: menu.active || menu.expand,
                  }},[_c('router-link',{staticClass:"w-100",class:{ clickkkk: (menu.click = true) },attrs:{"to":{ name: menu.to }}},[_c('div',{staticClass:"menu-name-content"},[(menu.iconClass)?_c('font-awesome-icon',{staticClass:"nav-icon size-h3",attrs:{"icon":menu.iconClass,"size":menu.iconSize}}):_vm._e(),_c('span',{staticClass:"menu-title"},[_vm._v(_vm._s(menu.title))])],1)])],1)]:_vm._e()]})],2)])])],1),_c('div',{staticClass:"sidemenu-toggle-collapse",on:{"click":function($event){return _vm.sidemenuCollapse()}}},[_c('img',{attrs:{"src":require("@/assets/images/menu-button-left.png")}})])])])
}
var staticRenderFns = []

export { render, staticRenderFns }