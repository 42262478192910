<template>
  <div>
    <div id="HeaderLayout" style="position: fixed; width: 100%; z-index: 99">
      <div class="d-flex align-items-center px-3 py-2 header-layout shadow-sm">
        <img class="header-logo" src="@/assets/logo.png" />
        <div
          class="flex-fill px-3 size-h2 align-items-center header-title-name"
        >
          ระบบแจ้งประสบภัยพิบัติอัจฉริยะสำหรับปางช้างแบบเป็นปัจจุบัน กรมปศุสัตว์
        </div>
        <b-navbar-nav class="mx-2 nav-logout">
          <b-nav-item-dropdown right class="nav-dropdown-logout">
            <template #button-content>
              <div class="d-flex flex-row align-items-center">
                <div class="d-flex flex-column flex-1 text-right">
                  <span
                    class="ml-3 mr-2 header-layout-user"
                    style="display: inline-block; min-width: 100px"
                    >{{ usernameLogin }}</span
                  >
                  <span
                    v-show="isAdmin != null"
                    class="ml-3 mr-2 header-layout-position"
                    style="display: inline-block; min-width: 100px"
                    >{{ isAdmin ? departmentLogin : "สมาชิกเว็บไซต์" }}</span
                  >
                </div>

                <b-avatar
                  class="header-layout-avatar"
                  :src="userImage"
                  onerror="javascript:this.src='/images/default-profile.png'"
                ></b-avatar>
              </div>
            </template>
            <b-dropdown-item href="#" @click="updateProfile"
              ><font-awesome-icon
                class="mr-2 font-primary"
                :icon="['fas', 'user-pen']"
              />
              แก้ไขข้อมูลส่วนตัว</b-dropdown-item
            >
            <b-dropdown-item href="#" @click="getLogout"
              ><font-awesome-icon
                class="mr-2 font-danger"
                :icon="['fas', 'power-off']"
              />
              ออกจากระบบ</b-dropdown-item
            >
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </div>
    </div>

    <div
      class="navbar-mobile"
      style="position: fixed; width: 100%; z-index: 99"
    >
      <b-navbar toggleable class="px-3 py-1 shadow-sm">
        <b-navbar-brand href="#">
          <img class="header-logo" src="@/assets/logo.png" />
        </b-navbar-brand>

        <b-navbar-toggle target="navbar-toggle-collapse">
          <template #default="{ expanded }">
            <b-icon v-if="expanded" icon="x-lg"></b-icon>
            <b-icon v-else icon="list"></b-icon>
          </template>
        </b-navbar-toggle>

        <b-collapse id="navbar-toggle-collapse" is-nav class="mt-2">
          <b-navbar-nav class="ml-auto">
            <b-nav-item href="#">
              <div class="d-flex flex-row align-items-center">
                <b-avatar
                  class="header-layout-avatar"
                  :src="userImage"
                  onerror="javascript:this.src='/images/default-profile.png'"
                ></b-avatar>
                <div class="d-flex flex-column flex-1">
                  <span
                    class="ml-3 mr-2 header-layout-user"
                    style="display: inline-block; min-width: 100px"
                    >{{ usernameLogin }}</span
                  >
                  <span
                    v-show="isAdmin != null"
                    class="ml-3 mr-2 header-layout-position"
                    style="display: inline-block; min-width: 100px"
                    >{{ isAdmin ? departmentLogin : "สมาชิกเว็บไซต์" }}</span
                  >
                </div>
              </div></b-nav-item
            >

            <b-dropdown-divider></b-dropdown-divider>
            <template v-for="(menu, index) in menus">
              <template v-if="menu.parentId == 0 && menu.hasSub">
                <b-nav-item
                  href="#"
                  :key="index"
                  :class="{
                    'active-submenu': menu.active || menu.expand,
                    active: menu.active || menu.expand,
                  }"
                >
                  <font-awesome-icon
                    class="nav-icon mr-2"
                    v-if="menu.iconClass"
                    :icon="menu.iconClass"
                    :size="menu.iconSize"
                  />

                  <span class="flex-fill menu-title">{{ menu.title }}</span>
                  <ul v-if="menu.children" class="ul-submenu">
                    <template v-for="(child, index2) in menu.children">
                      <li
                        class="li-submenu sub-level"
                        :key="index2"
                        :class="{
                          'active-submenu': child.active || child.expand,
                          active: child.active || child.expand,
                        }"
                      >
                        <router-link class="w-100" :to="{ name: child.to }">
                          <div class="menu-name-content">
                            <span class="menu-title pd-menu">{{
                              child.title
                            }}</span>
                          </div>
                        </router-link>
                      </li>
                    </template>
                  </ul>
                </b-nav-item>
              </template>
              <!--  parent-level , can click -->
              <template v-else-if="menu.parentId == 0">
                <b-nav-item
                  href="#"
                  class="li-menu parent-level-click"
                  :key="index"
                  :class="{
                    'active-submenu': menu.active || menu.expand,
                    active: menu.active || menu.expand,
                  }"
                  ><router-link
                    class="w-100"
                    :to="{ name: menu.to }"
                    :class="{ clickkkk: (menu.click = true) }"
                  >
                    <div class="menu-name-content">
                      <font-awesome-icon
                        class="nav-icon mr-2"
                        v-if="menu.iconClass"
                        :icon="menu.iconClass"
                        :size="menu.iconSize"
                      />
                      <span class="menu-title">{{ menu.title }}</span>
                    </div>
                  </router-link></b-nav-item
                >
              </template>
            </template>

            <b-dropdown-divider></b-dropdown-divider>

            <b-nav-item href="#" @click="updateProfile"
              ><font-awesome-icon
                class="mr-2 font-primary"
                :icon="['fas', 'user-pen']"
              />
              แก้ไขข้อมูลส่วนตัว</b-nav-item
            >

            <b-nav-item href="#" @click="getLogout"
              ><font-awesome-icon
                class="mr-2 font-danger"
                :icon="['fas', 'power-off']"
              />
              ออกจากระบบ</b-nav-item
            >
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </div>
  </div>
</template>

<script>
export default {
  name: "header-layout",
  data() {
    return {
      userImage: null,
      isAdmin: null,
      // usernameLogin: null,
      // departmentLogin: null,
      menus: [],
    };
  },
  methods: {
    getLogout() {
      this.alertConfirm({ title: "คุณต้องการออกจากระบบใช่หรือไม่" }).then(
        (res) => {
          if (res) {
            this.$store
              .dispatch("account/logout")
              .then(
                (res) => {},
                (error) => {
                  console.log("getLogout():error", error);
                }
              )
              .finally(() => {
                this.$router.push({ name: "HomePage" });
              });
          }
        }
      );
    },
    updateProfile() {
      //go to update profile page
      this.$router.push({
        name: "EditProfile",
      });
    },

    sidemenuCollapse() {
      this.$store.commit("setMenuCollapse");
      // this.$emit("toggle-menu-func", value);
    },
    matchMenuSide(route) {
      const _name = route.name;
      this.menus.map((f) => {
        f.active = false;
        f.expand = false;

        if (f.to === _name) {
          f.active = true;
          f.expand = true;
        } else if (f.to !== undefined && route.meta?.rootMenu === f.to) {
          f.active = true;
          f.expand = true;
        }
        return { ...f };
      });
    },
  },
  //created() {
  //this.isAdmin = this.$utils.main.getIsAdmin();
  // const userInfo = this.$utils.main.getUserInfo() || {};

  // this.usernameLogin = userInfo?.userName || "-";
  // this.departmentLogin = userInfo?.departmentName || "-";
  //},
  async created() {
    this.isAdmin = this.$utils.main.getIsAdmin();
    window._sidebar = this;

    let _menu = this.$utils.main.getPermissionMenu();

    // this.menus = _menu
    //   .map((m) => {
    //     return {
    //       ...m,
    //       active: false,
    //       expand: false,
    //     };
    //   })
    //   .filter((f) => f.showMenu)
    //   .sort(function (a, b) {
    //     return parseFloat(a._order) - parseFloat(b._order);
    //   });

    let lstMenu = [];

    _menu
      .filter((f) => f.showMenu)
      .forEach((ele) => {
        let lstMenuChild = [];

        if (ele.parentId == 0 && ele.hasSub) {
          lstMenuChild =
            _menu.filter((f) => {
              return f.parentId == ele.menuId && f.showMenu;
            }) || [];

          let tempmenu = null;
          if (ele.hasSub && lstMenuChild.length > 0) {
            tempmenu = JSON.parse(JSON.stringify(ele));
            tempmenu.children = [...lstMenuChild];
          }
          if (tempmenu) {
            lstMenu.push(tempmenu);
          }
        } else if (ele.parentId == 0) {
          lstMenu.push(ele);
        }
        // if (element.parentId !== 0) {
        //   element.children.forEach((element2) => {
        //     const show = this.findSidebar(element2.menuId);
        //     if (show) {
        //       lstMenuChild.push(element2);
        //     }
        //   });
        // }
      });

    this.menus = lstMenu;

    this.$nextTick(() => {
      this.matchMenuSide(this.$route);
    });
  },
  computed: {
    // userImage: function() {
    //   // const detail = this.$utils.main.getDetail() || {}
    //   // return detail?.image || ''
    // }
    usernameLogin: function () {
      return this.$utils.main.getUserInfo()?.userName || "-";
    },
    departmentLogin: function () {
      return this.$utils.main.getUserInfo()?.departmentName || "-";
    },
  },
  watch: {
    $route(route) {
      this.matchMenuSide(route);
    },
  },
};
</script>

<style lang="scss">
.header-layout {
  background: white;
  // padding-left: $sidebar-width;
  height: $header-layout-height;
  transition: all $sidebar-delay ease-in-out;
  justify-content: space-between;

  // .app-screen-medium.menu-collapse &,
  // .app-screen-large.menu-collapse & {
  //   height: $header-layout-height-sm;
  //   padding-left: $sidebar-width-sm;
  // }

  .nav-dropdown-logout {
    > a {
      display: flex;
      align-items: center;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0.25rem;
      bottom: 0.25rem;
      left: 0;
      width: 2px;
      // background: lighten($darkgray, 40%);
    }

    .dropdown-item {
      padding: 0.8rem 1.5rem;
    }
  }

  .header-layout-avatar {
    margin: 0 0.25rem;
  }

  .header-layout-user {
    color: $dark;
  }

  .header-layout-position {
    color: $super-gray;
  }
}
</style>
