import {
  editFileToFormDataFile,
  editFileToFormDataFileList,
  objToFormData,
} from "@/utils/convert-utils";
import httpClient from "../httpClient";

const END_POINT = "ElephantHouse";

class ElephantHouseService {
  async getListElephantHouse(params) {
    const config = {
      method: "get",
      url: `${END_POINT}/listElephantHouse`,
      params: params,
    };

    const response = await httpClient(config);
    return response || {};
  }

  async getListById(params) {
    const config = {
      method: "get",
      url: `${END_POINT}/listById`,
      params: params,
    };

    const response = await httpClient(config);
    if (response && response.data) {
      return response.data;
    } else {
      return response || {};
    }
  }

  async getListByIdShort(params) {
    const config = {
      method: "get",
      url: `${END_POINT}/listByIdShort`,
      params: params,
    };

    const response = await httpClient(config);
    if (response && response.data) {
      return response.data || {};
    } else {
      return response || {};
    }
  }

  async postAdd(params) {
    let formData = new FormData();

    const {
      elephantHouse,
      veterinarian,
      ownerElephantHouse,
      coordinator,
      veterinarianFile,
      ownerElephantHouseFile,
      ...rest
    } = params;

    const { filePictureElephant, ...restElephantHouse } = elephantHouse;

    objToFormData(rest, "", formData);

    objToFormData(restElephantHouse, "elephantHouse", formData);
    if (filePictureElephant.length > 0) {
      filePictureElephant.forEach((element, i) => {
        formData.append(`elephantHouse.filePictureElephant`, element.file);
      });
    }
    objToFormData(veterinarian, "veterinarian", formData);
    objToFormData(ownerElephantHouse, "ownerElephantHouse", formData);
    objToFormData(coordinator, "coordinator", formData);

    //file veterinarian
    if (veterinarianFile.veterinaryCertificateFile) {
      formData.append(
        `veterinarian.veterinaryCertificateFile`,
        veterinarianFile.veterinaryCertificateFile
      );
    }
    if (veterinarianFile.courseFile) {
      formData.append(`veterinarian.courseFile`, veterinarianFile.courseFile);
    }

    //file ownerElephantHouse
    if (ownerElephantHouseFile.plotFile) {
      formData.append(
        `ownerElephantHouse.plotFile`,
        ownerElephantHouseFile.plotFile
      );
    }
    if (ownerElephantHouseFile.locationMapFile) {
      formData.append(
        `ownerElephantHouse.locationMapFile`,
        ownerElephantHouseFile.locationMapFile
      );
    }
    if (ownerElephantHouseFile.organizationChartFile) {
      formData.append(
        `ownerElephantHouse.organizationChartFile`,
        ownerElephantHouseFile.organizationChartFile
      );
    }
    if (ownerElephantHouseFile.licenseFile) {
      formData.append(
        `ownerElephantHouse.licenseFile`,
        ownerElephantHouseFile.licenseFile
      );
    }
    if (ownerElephantHouseFile.standardCertificateFile) {
      formData.append(
        `ownerElephantHouse.standardCertificateFile`,
        ownerElephantHouseFile.standardCertificateFile
      );
    }
    if (ownerElephantHouseFile.ownerElephantHouseOtherFile.length > 0) {
      ownerElephantHouseFile.ownerElephantHouseOtherFile.forEach(
        (element, i) => {
          formData.append(
            `ownerElephantHouse.ownerElephantHouseOtherFile`,
            element
          );
        }
      );
    }

    const config = {
      method: "post",
      url: `${END_POINT}/add`,
      data: formData,
    };

    const response = await httpClient(config);
    return response || {};
  }

  async postEdit(params) {
    let formData = new FormData();

    const {
      elephantHouse,
      veterinarian,
      ownerElephantHouse,
      coordinator,
      veterinarianFile,
      ownerElephantHouseFile,
      ...rest
    } = params;

    const { filePictureElephant, ...restElephantHouse } = elephantHouse;

    objToFormData(rest, "", formData);

    objToFormData(restElephantHouse, "elephantHouse", formData);
    if (filePictureElephant.length > 0) {
      editFileToFormDataFileList(
        formData,
        filePictureElephant,
        "elephantHouse.filePictureElephant"
      );
    }

    objToFormData(veterinarian, "veterinarian", formData);

    const { ownerElephantHouseOtherFile, ...restOwner } = ownerElephantHouse;
    objToFormData(restOwner, "ownerElephantHouse", formData);
    objToFormData(coordinator, "coordinator", formData);

    //file veterinarian
    if (veterinarianFile.veterinaryCertificateFile) {
      editFileToFormDataFile(
        formData,
        veterinarianFile.veterinaryCertificateFile,
        "veterinarian.veterinaryCertificateFile"
      );
    }
    if (veterinarianFile.courseFile) {
      editFileToFormDataFile(
        formData,
        veterinarianFile.courseFile,
        "veterinarian.courseFile"
      );
    }

    //file ownerElephantHouse
    if (ownerElephantHouseFile.plotFile) {
      editFileToFormDataFile(
        formData,
        ownerElephantHouseFile.plotFile,
        "ownerElephantHouse.plotFile"
      );
    }
    if (ownerElephantHouseFile.locationMapFile) {
      editFileToFormDataFile(
        formData,
        ownerElephantHouseFile.locationMapFile,
        "ownerElephantHouse.locationMapFile"
      );
    }
    if (ownerElephantHouseFile.organizationChartFile) {
      editFileToFormDataFile(
        formData,
        ownerElephantHouseFile.organizationChartFile,
        "ownerElephantHouse.organizationChartFile"
      );
    }
    if (ownerElephantHouseFile.licenseFile) {
      editFileToFormDataFile(
        formData,
        ownerElephantHouseFile.licenseFile,
        "ownerElephantHouse.licenseFile"
      );
    }
    if (ownerElephantHouseFile.standardCertificateFile) {
      editFileToFormDataFile(
        formData,
        ownerElephantHouseFile.standardCertificateFile,
        "ownerElephantHouse.standardCertificateFile"
      );
    }
    if (ownerElephantHouseFile.ownerElephantHouseOtherFile.length > 0) {
      editFileToFormDataFileList(
        formData,
        ownerElephantHouseFile.ownerElephantHouseOtherFile,
        "ownerElephantHouse.ownerElephantHouseOtherFile"
      );
    }

    const config = {
      method: "post",
      url: `${END_POINT}/edit`,
      data: formData,
    };

    const response = await httpClient(config);
    return response || {};
  }

  async postDelete(params) {
    const config = {
      method: "post",
      url: `${END_POINT}/delete`,
      params: params,
    };

    const response = await httpClient(config);
    return response || {};
  }

  async postFileStream(id) {
    const params = {
      id: id,
    };
    const config = {
      method: "post",
      url: `${END_POINT}/file`,
      params: params,
      responseType: "blob",
    };

    const response = await httpClient(config);

    return response || undefined;
  }

  async getVerifyLicenseNumber(params) {
    const config = {
      method: "get",
      url: `${END_POINT}/verifyLicenseNumber`,
      params: params,
    };

    const response = await httpClient(config);
    return response || {};
  }
}

export default new ElephantHouseService();
