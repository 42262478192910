const menuList = [
  {
    menuId: 1,
    parentId: 0,
    title: "ข้อมูลปางช้าง",
    titleCollapse: "ข้อมูลปางช้าง",
    iconClass: ["fas", "file-lines"],
    iconFile: "pang-chang-info-menu-icon",
    iconSize:"lg",
    auth: ["auth_pangchang"],
    to: "PangChangInfo",
  },
  {
    menuId: 2,
    parentId: 0,
    title: "ข้อมูลช้าง",
    titleCollapse: "ข้อมูลช้าง",
    iconClass: ["fab", "evernote"],
    iconFile: "chang-info-menu-icon",
    iconSize:"xl",
    auth: ["auth_chang"],
    to: "ChangInfo",
  },
  {
    menuId: 3,
    parentId: 0,
    title: "แจ้งประสบภัยพิบัติ",
    titleCollapse: "แจ้งประสบภัยพิบัติ",
    iconClass: ["fas", "triangle-exclamation"],
    iconFile: "disaster-warning-menu-icon",
    iconSize:"lg",
    auth: ["auth_disaster"],
    to: "DisasterWarningInfo",
  },
  {
    menuId: 4,
    parentId: 0,
    title: "รายงาน",
    titleCollapse: "รายงาน",
    iconClass: ["fas", "file-circle-check"],
    iconFile: "report-menu-icon",
    iconSize:"lg",
    auth: ["auth_report"],
    hasSub: true,
  },
  {
    menuId: 5,
    parentId: 4,
    title: "รายงานปริมาณปางช้าง",
    titleCollapse: "รายงานปริมาณปางช้าง",
    auth: ["auth_report"],
    to: "ReportPangChang",
  },
  {
    menuId: 6,
    parentId: 4,
    title: "รายงานปริมาณช้าง",
    titleCollapse: "รายงานปริมาณช้าง",
    auth: ["auth_report"],
    to: "ReportChang",
  },
  {
    menuId: 7,
    parentId: 4,
    title: "รายงานการแจ้งเตือนภัยพิบัติ",
    titleCollapse: "รายงานการแจ้งเตือนภัยพิบัติ",
    auth: ["auth_report"],
    to: "ReportDisaster",
  },
  // {
  //   menuId: 8,
  //   parentId: 4,
  //   title: "รายงานการย้ายช้าง",
  //   titleCollapse: "รายงานการย้ายช้าง",
  //   auth: ["auth_report"],
  //   to: "ReportMoveChang",
  // },
  {
    menuId: 8,
    parentId: 0,
    title: "การจัดการข้อมูลผู้ใช้",
    titleCollapse: "การจัดการข้อมูลผู้ใช้",
    iconClass: ["fas", "users"],
    iconFile: "management-menu-icon",
    iconSize:"lg",
    auth: ["auth_system"],
    hasSub: true,
  },
  {
    menuId: 9,
    parentId: 8,
    title: "ผู้ใช้งาน",
    titleCollapse: "ผู้ใช้งาน",
    auth: ["auth_usermanagement"],
    to: "UserManagement",
  },
  {
    menuId: 10,
    parentId: 8,
    title: "ประเภทผู้ใช้งาน",
    titleCollapse: "ประเภทผู้ใช้งาน",
    auth: ["auth_usertype"],
    to: "UserTypeManagement",
  },
  {
    menuId: 11,
    parentId: 0,
    title: "สมาชิกเว็บไซต์",
    titleCollapse: "สมาชิกเว็บไซต์",
    iconClass: ["fas", "star"],
    iconFile: "member-menu-icon",
    iconSize:"lg",
    auth: ["auth_member"],
    to: "MemberInfo",
  },
];

const state = {
  menus: [...menuList],
};

export const menus = {
  namespaced: true,
  state,
};
