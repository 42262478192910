import NavigationBar from "./cores/NavigationBar";
import Datepicker from "./cores/Datepicker";
import VueMultiselect from "./cores/VueMultiselect";
import ImageUpload from "./cores/ImageUpload";
import FileUpload from "./cores/FileUpload";
import GroupSelect from "./cores/GroupSelect";
import PhoneMultiple from "./cores/PhoneMultiple";

const ComponentPlugin = {
  install(Vue, options) {
    [
      NavigationBar,
      Datepicker,
      VueMultiselect,
      ImageUpload,
      FileUpload,
      GroupSelect,
      PhoneMultiple,
    ].forEach((component) => {
      Vue.component(component.name, component);
    });
  },
};

export default ComponentPlugin;
