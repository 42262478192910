import httpClient from "../httpClient";

const END_POINT = "Account";

class MemberService {
  async getListUser(params) {
    const config = {
      method: "get",
      url: `${END_POINT}/listUser`,
      params: params,
    };

    const response = await httpClient(config);
    return response || {};
  }

  async getListUserById(params) {
    const config = {
      method: "get",
      url: `${END_POINT}/listUserById`,
      params: params,
    };

    const response = await httpClient(config);
    return response?.data || {};
  }
}

export default new MemberService();
