import Vue from "vue";
import VueRouter from "vue-router";
import LoginLayout from "@/components/layouts/login/LoginLayout";
import AppLayout from "@/components/layouts/app/AppLayout";
import RegisterLayout from "@/components/layouts/login/RegisterLayout";
import { TokenService } from "@/services/storage.service";
import { getLanding, permission, getIsAdmin } from "@/utils/main-utils";
import store from "@/store/index";

Vue.use(VueRouter);

const EmptyParentComponent = {
  template: "<router-view></router-view>",
  meta: {
    empty: true,
  },
};

const routes = [
  {
    path: "",
    redirect: { name: "HomePage" },
  },
  {
    path: "*",
    redirect: { name: "HomePage" },
  },
  {
    path: "/",
    component: LoginLayout,
    meta: {
      login: true,
    },
    children: [
      {
        name: "Login",
        path: "login",
        component: () => import("../views/Auth/Login.vue"),
      },
    ],
  },
  {
    path: "/",
    component: LoginLayout,
    meta: {
      public: true,
    },
    children: [
      {
        name: "HomePage",
        path: "",
        component: () => import("../views/HomePage/Index.vue"),
      },
    ],
  },
  {
    path: "/",
    component: RegisterLayout,
    meta: {
      public: true,
      register: true,
    },
    children: [
      {
        name: "Register",
        path: "register",
        component: () => import("../views/Auth/Register.vue"),
      },
    ],
  },
  {
    name: "EditProfile",
    path: "/update-profile",
    meta: {
      reqAuth: true,
      editProfile: true,
    },
    component: () => {
      return import("../views/HomePage/UpdateProfile.vue");
      // if (getIsAdmin()) {
      //   return import("../views/HomePage/EditAdmin.vue");
      // } else {
      //   return import("../views/HomePage/EditMember.vue");
      // }
    },
  },
  {
    path: "/",
    component: AppLayout,
    meta: {
      reqAuth: true,
    },
    children: [
      {
        path: "PangChangInfo",
        redirect: { name: "PangChangInfo" },
        component: EmptyParentComponent,
        children: [
          {
            name: "PangChangInfo",
            path: "",
            component: () => import("../views/PangChangInfo/Index.vue"),
          },
          {
            name: "PangChangFormDefault",
            path: "Detail",
            redirect: { name: "PangChangInfo" },
          },
          {
            name: "PangChangFormAdd",
            path: "Detail/:mode",
            meta: {
              rootMenu: "PangChangInfo",
            },
            component: () => import("../views/PangChangInfo/Form.vue"),
          },
          {
            name: "PangChangFormEdit",
            path: "Detail/:mode",
            meta: {
              rootMenu: "PangChangInfo",
            },
            component: () => import("../views/PangChangInfo/Form.vue"),
          },
        ],
      },
      {
        path: "ChangInfo",
        redirect: { name: "ChangInfo" },
        component: EmptyParentComponent,
        children: [
          {
            name: "ChangInfo",
            path: "",
            component: () => import("../views/ChangInfo/Index.vue"),
          },
          {
            name: "ChangFormAdd",
            path: "Detail/:mode",
            meta: {
              rootMenu: "ChangInfo",
            },
            component: () => import("../views/ChangInfo/Form.vue"),
          },
          {
            name: "ChangFormEdit",
            path: "Detail/:mode",
            meta: {
              rootMenu: "ChangInfo",
            },
            component: () => import("../views/ChangInfo/Form.vue"),
          },
        ],
      },
      {
        path: "DisasterWarning",
        redirect: { name: "DisasterWarningInfo" },
        component: EmptyParentComponent,
        children: [
          {
            name: "DisasterWarningInfo",
            path: "",
            component: () => import("../views/DisasterWarning/Index.vue"),
          },
          {
            name: "DisasterWarningAdd",
            path: "Detail/:mode",
            meta: {
              rootMenu: "DisasterWarningInfo",
            },
            component: () => import("../views/DisasterWarning/Form.vue"),
          },
          {
            name: "DisasterWarningEdit",
            path: "Detail/:mode",
            meta: {
              rootMenu: "DisasterWarningInfo",
            },
            component: () => import("../views/DisasterWarning/Form.vue"),
          },
        ],
      },

      {
        path: "Report",
        redirect: { name: "ReportPangChang" },
        component: EmptyParentComponent,
        children: [
          {
            name: "ReportPangChang",
            path: "ReportPangChangInfo",
            component: () => import("../views/Report/ReportPangChang.vue"),
          },
          {
            name: "ReportChang",
            path: "ReportChangInfo",
            component: () => import("../views/Report/ReportChang.vue"),
          },
          {
            name: "ReportDisaster",
            path: "ReportDisasterInfo",
            component: () => import("../views/Report/ReportDisaster.vue"),
          },
          // {
          //   name: "ReportMoveChang",
          //   path: "ReportMoveChangInfo",
          //   component: () => import("../views/Report/ReportMoveChang.vue"),
          // },
        ],
      },
      {
        path: "UserManagement",
        redirect: { name: "UserManagement" },
        component: EmptyParentComponent,
        children: [
          {
            name: "UserManagement",
            path: "",
            component: () => import("../views/UserManagement/User/Index.vue"),
          },
          {
            name: "UserFormAdd",
            path: "Detail/:mode",
            meta: {
              rootMenu: "UserManagement",
            },
            component: () => import("../views/UserManagement/User/Form.vue"),
          },
          {
            name: "UserFormEdit",
            path: "Detail/:mode",
            meta: {
              rootMenu: "UserManagement",
            },
            component: () => import("../views/UserManagement/User/Form.vue"),
          },
        ],
      },
      {
        path: "UserTypeManagement",
        redirect: { name: "UserTypeManagement" },
        component: EmptyParentComponent,
        children: [
          {
            name: "UserTypeManagement",
            path: "",
            component: () =>
              import("../views/UserManagement/UserType/Index.vue"),
          },
          {
            name: "UserTypeFormAdd",
            path: "Detail/:mode",
            meta: {
              rootMenu: "UserTypeManagement",
            },
            component: () =>
              import("../views/UserManagement/UserType/Form.vue"),
          },
          {
            name: "UserTypeFormEdit",
            path: "Detail/:mode",
            meta: {
              rootMenu: "UserTypeManagement",
            },
            component: () =>
              import("../views/UserManagement/UserType/Form.vue"),
          },
        ],
      },
      {
        path: "Member",
        redirect: { name: "MemberInfo" },
        component: EmptyParentComponent,
        children: [
          {
            name: "MemberInfo",
            path: "",
            component: () => import("../views/Member/Index.vue"),
          },
          {
            name: "MemberAdd",
            path: "Detail/:mode",
            meta: {
              rootMenu: "MemberInfo",
            },
            component: () => import("../views/Member/Form.vue"),
          },
          {
            name: "MemberEdit",
            path: "Detail/:mode",
            meta: {
              rootMenu: "MemberInfo",
            },
            component: () => import("../views/Member/Form.vue"),
          },
        ],
      },
      {
        name: "Example",
        path: "Example",
        component: () => import("../views/Example/Index.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  store.commit("setPageLoading", true);

  let changeNext;

  const haveToken = !!TokenService.getToken();
  const toMatchedLogin = to.matched.some((record) => record.meta.login);
  const toMatchedRegister = to.matched.some((record) => record.meta.register);
  const toMachedPublic = to.matched.some((record) => record.meta.public);
  const toMachedReqAuth = to.matched.some((record) => record.meta.reqAuth);
  const toEditProfile = to.matched.some((record) => record.meta.editProfile);

  console.log({
    haveToken,
    toMatchedLogin,
    toMatchedRegister,
    toMachedPublic,
    toMachedReqAuth,
  });

  let caseCheck = "";
  if (
    ((toMatchedLogin || toMatchedRegister) && !haveToken) ||
    (toMachedPublic && !toMatchedRegister)
  ) {
    caseCheck = 1;
  } else if (!haveToken && toMachedReqAuth) {
    caseCheck = 2;
    //goto login page
    return next({ name: "Login" });
  } else if ((toMatchedLogin || toMatchedRegister) && haveToken) {
    caseCheck = 3;
    //goto first menu (redirect to default)
    const to = getLanding();
    if (to) {
      changeNext = to;
    }
  } else if (toEditProfile && haveToken) {
    caseCheck = 4;
  } else {
    caseCheck = 5;
    //check menu permission
    const _permission = permission(to.meta?.rootMenu || to.name);
    console.log("5a: _permission", {
      rootMenu: to.meta?.rootMenu,
      name: to.name,
      permission: _permission,
    });
    if (!_permission.showMenu) {
      const to = getLanding();
      if (to) {
        console.log("5b", to);
        changeNext = to;
      } else {
        console.log("5c", to);
        //clear token, vuex account
        TokenService.removeToken();
        TokenService.removeRefreshToken();
        store.commit("account/logoutSuccess");

        return next({ name: "Login" });
      }
    }
  }

  console.log({ changeNext, caseCheck });

  if (changeNext) {
    if (from.name === changeNext) {
      // hide loading page if from and next is same route
      store.commit("setPageLoading", false);
    }
    changeNext = { name: changeNext };
  }

  return next(changeNext);
});

router.afterEach(async (to, from, next) => {
  // hide loading page
  store.commit("setPageLoading", false);
});

const VueRouterPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch((err) => err);
};

const VueRouterReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(to) {
  return VueRouterReplace.call(this, to).catch((err) => err);
};

export default router;
