<template>
  <div class="row core-image-upload">
    <div class="file-input" style="padding: 12px" v-if="!disabled">
      <input
        type="file"
        :name="genCompId"
        :id="genCompId"
        class="file-input__input"
        accept="image/*"
        @change="uploadPangImage($event)"
        multiple
      />
      <label class="file-input__label" :for="genCompId">
        <font-awesome-icon :icon="['fas', 'circle-plus']" />
        <div class="mt-2">Upload</div></label
      >
    </div>
    <div
      v-else-if="disabled && preview_list_old.length == 0"
      class="image-not-upload"
    >
      ไม่มีไฟล์อัพโหลด
    </div>

    <div
      class=""
      v-for="(data, index) in preview_list_old"
      :key="`old_${genCompId}_${index}`"
    >
      <div class="image-old">
        <img
          :src="data.preview"
          onerror="javascript:this.src='/images/not-found-image.png'"
          class=""
        />
        <!-- <img
          onerror="javascript:this.src='/images/not-found-image.png'"
          :src="getImage(data.tmpFile.fileId)"
          class=""
        /> -->

        <font-awesome-icon
          class="icon-delete"
          :icon="['fas', 'circle-xmark']"
          @click="deleteImgOld(data)"
          v-show="!disabled"
        />
      </div>
    </div>
    <div
      class=""
      v-for="(data, index) in preview_list_new"
      :key="`new_${genCompId}_${index}`"
    >
      <div class="image-new">
        <img :src="data.preview" class="" />
        <font-awesome-icon
          class="icon-delete"
          :icon="['fas', 'circle-xmark']"
          @click="deleteImgNew(index)"
          v-show="!disabled"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ElephantHouseService } from "@/services/main.service";
import { v4 as uuidv4 } from "uuid";
import { arrayBufferToImage } from "@/utils/file-utils";

export default {
  name: "custom-image-upload",
  props: {
    mode: {
      type: String,
      default: "add", // add, edit, view
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    maxUpload: {
      type: [Number, null],
      default: 5,
    },
    listImage: {
      type: [Array, null],
      default: () => [],
    },
    fileSize: {
      type: Number,
      default: () => {
        1048576; //bytes
      },
    },
    sumFileSize: {
      type: Number,
      default: () => 0,
    },
  },
  data() {
    return {
      image_list_new: [],
      image_list_old: [],
      preview_list_old: [],
      preview_list_new: [],
      genCompId: null,
    };
  },
  created() {
    window._imageupload = this;

    this.genCompId = "file-input_" + uuidv4();
  },
  mounted() {},
  methods: {
    countUploadImageAll() {
      return (
        this.image_list_old.filter((f) => !f.isDelete).length +
        this.image_list_new.length
      );
    },
    async uploadPangImage(event) {
      const input = event.target;

      if (input.files.length + this.countUploadImageAll() > this.maxUpload) {
        //alert
        this.alertFail({
          title: "สามารถลงรูปได้สูงสุด 5 รูป",
          showCloseBtn: true,
          isConfirm: true,
        });

        return;
      }

      let count = input.files.length;
      let index = 0;
      if (input.files) {
        while (count--) {
          let reader = new FileReader();
          reader.onload = (e) => {
            this.preview_list_new.push({
              preview: e.target.result,
            });
          };
          this.image_list_new.push(input.files[index]);
          reader.readAsDataURL(input.files[index]);
          index++;
        }
      }
    },
    deleteImgNew(index, data) {
      if (this.disabled) {
        return;
      }
      this.image_list_new.splice(index, 1);
      this.preview_list_new.splice(index, 1);
      //emit
      this.change();
    },
    deleteImgOld(data) {
      if (this.disabled) {
        return;
      }

      data.isDelete = true;
      this.preview_list_old = [
        ...this.image_list_old.filter((f) => !f.isDelete),
      ];

      //emit
      this.change();
    },
    change() {
      const resultNew = this.image_list_new.map((m) => {
        return {
          tmpFile: m,
          isAdd: true,
        };
      });
      const _tmp = [...this.image_list_old, ...resultNew];
      this.$emit("input", _tmp);
    },
    async getImageSteam(fileId) {
      //const resultSteam = await this.$utils.file.downloadFileShowByFileId(fileId);
      const resultSteam = await ElephantHouseService.postFileStream(fileId)
        .then(async (response) => {
          if (response) {
            try {
              const blob = new Blob([response]);
              // const _temppp = await arrayBufferToImage(
              //   blob,
              //   "fileee.png",
              //   response.type
              // );
              const stream = URL.createObjectURL(blob);
              return stream;
            } catch (err) {
              console.log(err);
              return "";
            }
          }
        })
        .catch((err) => {
          console.log("err", err);
          return "";
        });

      return resultSteam;
    },
  },
  watch: {
    listImage: {
      handler: function (val) {
        //clear
        this.image_list_old = [];

        if (!!val) {
          [...val].forEach(async (ele, i) => {
            const timgSteam = await this.getImageSteam(ele.fileId);
            const resultImage = {
              tmpFile: ele,
              preview: timgSteam,
              // preview: m.filePath,
              // name: m.fileName,
              isOld: true,
            };
            this.image_list_old.push(resultImage);
          });

          // this.image_list_old = [...val].map(async (m) => {
          //   // const _steamImg = await this.$utils.file.downloadFileShowByFileId(
          //   //   m.fileId
          //   // );
          //   const timgSteam = await this.getImageSteam(m.fileId);
          //   return {
          //     tmpFile: m,
          //     preview: timgSteam,
          //     // preview: m.filePath,
          //     // name: m.fileName,
          //     isOld: true,
          //   };
          // });
        }
        //emit
        this.change();
      },
      deep: true,
    },
    image_list_old: {
      handler: function (newV, oldV) {
        this.preview_list_old = this.image_list_old.filter((f) => !f.isDelete);
      },
      deep: true,
    },
    image_list_new: {
      handler: function (newV, oldV) {
        this.change();
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.core-image-upload {
  .image-not-upload {
    background: $field-hover;
    padding: 15px 16px;
    border-radius: 9px;
    margin-left: 1rem !important;
    margin-right: 1rem !important;
    margin-top: 0.5rem !important;
    width: 100%;
  }
}
</style>
