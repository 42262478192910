import { getAccessToken } from "./main-utils.js";
import { objToQueryStr } from "./convert-utils.js";

const downloadFile = async ({ blob, filename, mimeType }) => {
  console.log({ blob, filename, mimeType });
  const resDownload = require("downloadjs");
  resDownload(blob, filename, mimeType);
};

const arrayBufferToImage = async (buffer, filename, type = "image/jpeg") => {
  const blob = new Blob([buffer], { type: type });
  return new File([blob], filename, { type: type });
};

const arrayBufferToFile = async (buffer, filename) => {
  const blob = new Blob([buffer], { type: "application/octet-stream" });
  return new File([blob], filename, { type: "application/octet-stream" });
};

// const downloadFileByLink = async (path) => {
//   const link = document.createElement("a");
//   try {
//     link.href = path; //"https://__API__/api/common/getfile/288";
//     link.click();
//     document.close();
//     return true;
//   } catch (err) {
//     document.close();
//     return false;
//   }
// };

const downloadFileByFileId = async (id) => {
  const link = document.createElement("a");
  try {
    let url = getLinkFileUrl(id);
    const token = getAccessToken();

    link.href = token ? url + "?access_token=" + token : url; //"https://__API__/api/common/getfile/288";
    link.target = "_blank";

    link.click();
    document.close();
    return true;
  } catch (err) {
    console.log(err);
    document.close();
    return false;
  }
};

const downloadFileShowByFileId = async (id) => {
  try {
    let url = getLinkFileUrl(id);
    const token = getAccessToken();

    const result = token ? url + "?access_token=" + token : url; //"https://__API__/api/common/getfile/288";
    console.log("downloadFileShowByFileId", { url, result });
    return result;
  } catch (err) {
    console.log("downloadFileShowByFileId", { err });
    return null;
  }
};

// const downloadFileTemplate = async (id) => {
//   const link = document.createElement("a");
//   try {
//     let url = getLinkFileTemplateUrl(id);
//     const token = getAccessToken();

//     link.href = token ? url + "&access_token=" + token : url;
//     link.target = "_blank";

//     link.click();
//     document.close();
//     return true;
//   } catch (err) {
//     document.close();
//     return false;
//   }
// };

const downloadFileExport = async (str, objParam) => {
  const link = document.createElement("a");

  try {
    const params = {
      ...objParam,
    };

    const token = getAccessToken();
    if (token) {
      params["access_token"] = token;
    }

    const queryStr = "?" + objToQueryStr(params);
    link.href = downloadUrl(str) + queryStr;
    link.target = "_blank";

    link.click();
    document.close();
    return true;
  } catch (err) {
    console.log(err);
    document.close();
    return false;
  }
};

const downloadUrl = (str) => {
  // if (!str) {
  //   return process.env.VUE_APP_BASE_API_DOWNLOAD;
  // } else {
  //   const arrStr = str.split("/");
  //   return `${process.env.VUE_APP_BASE_API_DOWNLOAD}${arrStr.join("/")}`;
  // }
};

const getLinkFileUrl = (fileId) => {
  return `${process.env.VUE_APP_BASE_API_DOWNLOAD}Common/getFile/${fileId}`;
};

export default {
  downloadFile,
  // downloadFileByLink,
  downloadFileByFileId,
  downloadFileShowByFileId,
  // downloadFileTemplate,
  downloadFileExport,
};

export {
  //downloadFileExport
  downloadFile,
  arrayBufferToImage,
  arrayBufferToFile,
};
