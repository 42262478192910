import httpClient from "../httpClient";

const END_POINT = "Filter";

class FilterService {
  async getPrefix() {
    const config = {
      method: "get",
      url: `${END_POINT}/prefix`,
    };

    const response = await httpClient(config);
    if (response && response.data) {
      return response.data || [];
    } else {
      return [];
    }
  }

  async getProvince(params) {
    const config = {
      method: "get",
      url: `${END_POINT}/Province`,
      params: params,
    };

    const response = await httpClient(config);
    if (response && response.data) {
      return response.data || [];
    } else {
      return [];
    }
  }

  async getAmphoe(params) {
    const config = {
      method: "get",
      url: `${END_POINT}/Amphoe`,
      params: params,
    };

    const response = await httpClient(config);
    if (response && response.data) {
      return response.data || [];
    } else {
      return [];
    }
  }

  async getTambon(params) {
    const config = {
      method: "get",
      url: `${END_POINT}/Tambon`,
      params: params,
    };

    const response = await httpClient(config);
    if (response && response.data) {
      return response.data || [];
    } else {
      return [];
    }
  }

  async getRegion() {
    const config = {
      method: "get",
      url: `${END_POINT}/Region`,
    };

    const response = await httpClient(config);
    if (response && response.data) {
      return response.data || [];
    } else {
      return [];
    }
  }

  async getElephantHouseStatus() {
    const config = {
      method: "get",
      url: `${END_POINT}/elephantHouseStatus`,
    };

    const response = await httpClient(config);
    if (response && response.data) {
      return response.data || [];
    } else {
      return [];
    }
  }

  async getElephantHouseType() {
    const config = {
      method: "get",
      url: `${END_POINT}/elephantHouseType`,
    };

    const response = await httpClient(config);
    if (response && response.data) {
      return response.data || [];
    } else {
      return [];
    }
  }

  async getElephantType() {
    const config = {
      method: "get",
      url: `${END_POINT}/elephantType`,
    };

    const response = await httpClient(config);
    if (response && response.data) {
      return response.data || [];
    } else {
      return [];
    }
  }

  async getElephantGender() {
    const config = {
      method: "get",
      url: `${END_POINT}/gender`,
    };

    const response = await httpClient(config);
    if (response && response.data) {
      return response.data || [];
    } else {
      return [];
    }
  }

  async getElephantStatus() {
    const config = {
      method: "get",
      url: `${END_POINT}/elephantStatus`,
    };

    const response = await httpClient(config);
    if (response && response.data) {
      return response.data || [];
    } else {
      return [];
    }
  }

  async getDisasterType() {
    const config = {
      method: "get",
      url: `${END_POINT}/disasterType`,
    };

    const response = await httpClient(config);
    if (response && response.data) {
      return response.data || [];
    } else {
      return [];
    }
  }

  async getDisasterStatus() {
    const config = {
      method: "get",
      url: `${END_POINT}/disasterStatus`,
    };

    const response = await httpClient(config);
    if (response && response.data) {
      return response.data || [];
    } else {
      return [];
    }
  }

  async getUserType() {
    const config = {
      method: "get",
      url: `${END_POINT}/userType`,
    };

    const response = await httpClient(config);
    if (response && response.data) {
      return response.data || [];
    } else {
      return [];
    }
  }

  async getDepartment(params) {
    const config = {
      method: "get",
      url: `${END_POINT}/department`,
      params: params,
    };

    const response = await httpClient(config);
    if (response && response.data) {
      return response.data || [];
    } else {
      return [];
    }
  }

  async getElephantHouseByUser(params) {
    const config = {
      method: "get",
      url: `${END_POINT}/elephantHouseByUser`,
      params: params,
    };

    const response = await httpClient(config);
    if (response && response.data) {
      return response.data || [];
    } else {
      return [];
    }
  }

  async getElephantHouse(params) {
    const config = {
      method: "get",
      url: `${END_POINT}/elephantHouse`,
      params: params,
    };

    const response = await httpClient(config);
    if (response && response.data) {
      return response.data || [];
    } else {
      return [];
    }
  }

  async getElephantHouseOther(params) {
    const config = {
      method: "get",
      url: `${END_POINT}/elephantHouseOther`,
      params: params,
    };

    const response = await httpClient(config);
    if (response && response.data) {
      return response.data || [];
    } else {
      return [];
    }
  }

  async getJuristicType() {
    const config = {
      method: "get",
      url: `${END_POINT}/juristicType`,
    };

    const response = await httpClient(config);
    if (response && response.data) {
      return response.data || [];
    } else {
      return [];
    }
  }
}

export default new FilterService();
