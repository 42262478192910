import httpClient from "../httpClient";

const END_POINT = "Report";

class ReportService {
  async getReportElephantHouse(params) {
    const config = {
      method: "get",
      url: `${END_POINT}/reportElephantHouse`,
      params: params,
    };

    const response = await httpClient(config);
    return response || {};
  }

  async getReportElephant(params) {
    const config = {
      method: "get",
      url: `${END_POINT}/reportElephant`,
      params: params,
    };

    const response = await httpClient(config);
    return response || {};
  }

  async getReportDisaster(params) {
    const config = {
      method: "get",
      url: `${END_POINT}/reportDisaster`,
      params: params,
    };

    const response = await httpClient(config);
    return response || {};
  }

  async getReportTransferElephant(params) {
    const config = {
      method: "get",
      url: `${END_POINT}/reportTransferElephant`,
      params: params,
    };

    const response = await httpClient(config);
    return response || {};
  }

  async postExportExcel(params) {
    const config = {
      method: "post",
      url: `${END_POINT}/exportExcel`,
      data: params,
      responseType: "blob",
    };

    const response = await httpClient(config);
    return response || undefined;
  }

  async getDisasterByPin(params) {
    const config = {
      method: "get",
      url: `${END_POINT}/disasterByPin`,
      params: params,
    };

    const response = await httpClient(config);
    return response || {};
  }

  async getElephantHouseByPin(params) {
    const config = {
      method: "get",
      url: `${END_POINT}/elephantHouseByPin`,
      params: params,
    };

    const response = await httpClient(config);
    return response || {};
  }
}

export default new ReportService();
