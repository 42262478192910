import {
  editFileToFormDataFileList,
  objToFormData,
} from "@/utils/convert-utils";
import httpClient from "../httpClient";

const END_POINT = "Disaster";

class DisasterService {
  async getList(params) {
    const config = {
      method: "get",
      url: `${END_POINT}/List`,
      params: params,
    };

    const response = await httpClient(config);
    return response || {};
  }
  async getListById(params) {
    const config = {
      method: "get",
      url: `${END_POINT}/ListById`,
      params: params,
    };

    const response = await httpClient(config);
    if (response && response.data) {
      return response.data;
    } else {
      return response || [];
    }
  }
  async postAdd(params) {
    let formData = new FormData();

    const { email, picture, ...rest } = params;

    objToFormData(rest, "", formData);
    if (email.length > 0) {
      email.forEach((element, i) => {
        formData.append(`email`, element);
      });
    }
    if (picture.length > 0) {
      picture.forEach((element, i) => {
        formData.append(`picture`, element);
      });
    }

    const config = {
      method: "post",
      url: `${END_POINT}/add`,
      data: formData,
    };

    const response = await httpClient(config);
    return response || {};
  }

  async postEdit(params) {
    let formData = new FormData();

    const { email, picture, ...rest } = params;

    objToFormData(rest, "", formData);

    if (email.length > 0) {
      email.forEach((element, i) => {
        formData.append(`email`, element);
      });
    }

    if (picture.length > 0) {
      editFileToFormDataFileList(formData, picture, "picture");
    }

    const config = {
      method: "post",
      url: `${END_POINT}/edit`,
      data: formData,
    };

    const response = await httpClient(config);
    return response || {};
  }

  async postDelete(params) {
    const config = {
      method: "post",
      url: `${END_POINT}/delete`,
      params: params,
    };

    const response = await httpClient(config);
    return response || {};
  }
}

export default new DisasterService();
