import httpClient from "../httpClient";

const END_POINT = "Transfer";

class TransferService {
  async getListTransfer(params) {
    const config = {
      method: "get",
      url: `${END_POINT}/listTransfer`,
      params: params,
    };

    const response = await httpClient(config);
    return response || {};
  }

  async getListTransferApprove() {
    const config = {
      method: "get",
      url: `${END_POINT}/listApprove`,
    };

    const response = await httpClient(config);
    return response || {};
  }

  async postAdd(params) {
    const config = {
      method: "post",
      url: `${END_POINT}/add`,
      data: params,
    };

    const response = await httpClient(config);
    return response || {};
  }

  async postUpdate(params) {
    const config = {
      method: "post",
      url: `${END_POINT}/update`,
      data: params,
    };

    const response = await httpClient(config);
    return response || {};
  }

  async postApprove(params) {
    const config = {
      method: "post",
      url: `${END_POINT}/approve`,
      data: params,
    };

    const response = await httpClient(config);
    return response || {};
  }

  async postDelete(params) {
    const config = {
      method: "post",
      url: `${END_POINT}/delete`,
      params: params,
    };

    const response = await httpClient(config);
    return response || {};
  }
}

export default new TransferService();
