import httpClient from "../httpClient";

const END_POINT = "Account";

class UserService {
  async getListAdmin(params) {
    const config = {
      method: "get",
      url: `${END_POINT}/listAdmin`,
      params: params,
    };

    const response = await httpClient(config);
    return response || {};
  }

  async getListAdminById(params) {
    const config = {
      method: "get",
      url: `${END_POINT}/listAdminById`,
      params: params,
    };

    const response = await httpClient(config);
    return response?.data || {};
  }

  async postEditAdmin(params) {
    const config = {
      method: "post",
      url: `${END_POINT}/editAdmin`,
      data: params,
    };

    const response = await httpClient(config);
    return response || {};
  }

  async postDeleteAdmin(params) {
    const config = {
      method: "post",
      url: `${END_POINT}/deleteAdmin`,
      data: params,
    };

    const response = await httpClient(config);
    return response || {};
  }

  async postUpdateStatusAdmin(params) {
    const config = {
      method: "post",
      url: `${END_POINT}/updateStatusAdmin`,
      params: params,
    };

    const response = await httpClient(config);
    return response || {};
  }

  async getListPermission(params) {
    const config = {
      method: "get",
      url: `${END_POINT}/listPermission`,
      params: params,
    };

    const response = await httpClient(config);
    return response || {};
  }

  async getListPermissionById(params) {
    const config = {
      method: "get",
      url: `${END_POINT}/listPermissionById`,
      params: params,
    };

    const response = await httpClient(config);
    return response?.data || {};
  }

  async postAddPermission(params) {
    const config = {
      method: "post",
      url: `${END_POINT}/addPermission`,
      data: params,
    };

    const response = await httpClient(config);
    return response || {};
  }

  async postEditPermission(params) {
    const config = {
      method: "post",
      url: `${END_POINT}/editPermission`,
      data: params,
    };

    const response = await httpClient(config);
    return response || {};
  }

  async postDeletePermission(params) {
    const config = {
      method: "post",
      url: `${END_POINT}/deletePermission`,
      params: params,
    };

    const response = await httpClient(config);
    return response || {};
  }

  async postUpdateStatusPermission(params) {
    const config = {
      method: "post",
      url: `${END_POINT}/updateStatusPermission`,
      params: params,
    };

    const response = await httpClient(config);
    return response || {};
  }
}

export default new UserService();
